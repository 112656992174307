// frontend/src/components/PlayerStatisticsTable.js  
/**  
 * File Name: PlayerStatisticsTable.js  
 * Location: src/components  
 * Description: Component to display player overall statistics, including positions played.  
 */  

import React from 'react';  
import PropTypes from 'prop-types';  
import { Table, TableHeader, TableRow, TableCell, TableBody } from '@fluentui/react-components';  

const PlayerStatisticsTable = ({ playerStats }) => (  
  <Table>  
    <TableHeader>  
      <TableRow>  
        <TableCell>Player</TableCell>  
        <TableCell>Goals</TableCell>  
        <TableCell>Shots</TableCell>  
        <TableCell>Shooting Percentage</TableCell>  
        <TableCell>Turnovers</TableCell>  
        <TableCell>Points</TableCell>  
        <TableCell>Positions Played</TableCell>  
      </TableRow>  
    </TableHeader>  
    <TableBody>  
      {playerStats.map((playerStat) => (  
        <TableRow key={playerStat.playerId || playerStat.playerName}>  
          <TableCell>{playerStat.playerName}</TableCell>  
          <TableCell>{playerStat.totalGoals}</TableCell>  
          <TableCell>{playerStat.totalShots}</TableCell>  
          <TableCell>{playerStat.shootingPercentage}%</TableCell>  
          <TableCell>{playerStat.totalTurnovers}</TableCell>  
          <TableCell>{playerStat.totalPoints}</TableCell>  
          <TableCell>  
            {playerStat.positionsPlayed ? [...playerStat.positionsPlayed].join(', ') : ''}  
          </TableCell>  
        </TableRow>  
      ))}  
    </TableBody>  
  </Table>  
);  

PlayerStatisticsTable.propTypes = {  
  playerStats: PropTypes.array.isRequired,  
};  

export default PlayerStatisticsTable;  
