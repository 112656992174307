// frontend/src/pages/SettingsPage.js  
/**      
 * File Name: SettingsPage.js      
 * Description: Enhanced Settings page connected to the backend for updating user profile and preferences.  
 */  
  
import React, { useState, useEffect, useContext } from 'react';  
import {  
  Input,  
  Label,  
  Button,  
  Checkbox,  
  makeStyles,  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  shorthands  
} from '@fluentui/react-components';  
import UserController from '../controllers/UserController';  
import { AuthContext } from '../contexts/AuthContext';  
  
const useStyles = makeStyles({  
  content: {  
    padding: '20px',  
    maxWidth: '600px',  
    margin: '0 auto',  
  },  
  section: {  
    marginTop: '30px',  
    ...shorthands.borderBottom('1px', 'solid', '#ddd'),  
    paddingBottom: '20px',  
  },  
  formField: {  
    margin: '15px 0',  
  },  
});  
  
function SettingsPage() {  
  const { user } = useContext(AuthContext);  
  const [coachName, setCoachName] = useState('');  
  const [email, setEmail] = useState('');  
  const [receiveUpdates, setReceiveUpdates] = useState(true);  
  const [isDialogOpen, setIsDialogOpen] = useState(false);  
  const styles = useStyles();  
  
  useEffect(() => {  
    fetchUserProfile();  
  }, []);  
  
  const fetchUserProfile = async () => {  
    try {  
      const userProfile = await UserController.getUserProfile();  
      setCoachName(userProfile.name || '');  
      setEmail(userProfile.email || '');  
      setReceiveUpdates(userProfile.receiveUpdates || false);  
    } catch (error) {  
      console.error('Error fetching user profile:', error);  
    }  
  };  
  
  const updateProfile = async (event) => {  
    event.preventDefault();  
    try {  
      await UserController.updateUserProfile({  
        name: coachName,  
        email,  
        receiveUpdates,  
      });  
      setIsDialogOpen(true);  
    } catch (error) {  
      console.error('Error updating user profile:', error);  
    }  
  };  
  
  const closeDialog = () => {  
    setIsDialogOpen(false);  
  };  
  
  return (  
    <div className={styles.content}>  
      <h2>Settings</h2>  
  
      <div className={styles.section}>  
        <h3>User Profile</h3>  
        <form onSubmit={updateProfile}>  
          <div className={styles.formField}>  
            <Label htmlFor="coach_name">Name:</Label>  
            <Input  
              id="coach_name"  
              value={coachName}  
              onChange={(e) => setCoachName(e.target.value)}  
            />  
          </div>  
          <div className={styles.formField}>  
            <Label htmlFor="email">Email:</Label>  
            <Input  
              id="email"  
              value={email}  
              onChange={(e) => setEmail(e.target.value)}  
              type="email"  
            />  
          </div>  
          <div className={styles.formField}>  
            <Button type="submit" appearance="primary">  
              Update Profile  
            </Button>  
          </div>  
        </form>  
      </div>  
  
      <div className={styles.section}>  
        <h3>App Preferences</h3>  
        <form onSubmit={updateProfile}>  
          <div className={styles.formField}>  
            <Checkbox  
              label="Receive updates via email"  
              checked={receiveUpdates}  
              onChange={(e, data) => setReceiveUpdates(data.checked)}  
            />  
          </div>  
          <div className={styles.formField}>  
            <Button type="submit" appearance="primary">  
              Save Preferences  
            </Button>  
          </div>  
        </form>  
      </div>  
  
      <Dialog open={isDialogOpen} onOpenChange={closeDialog}>  
        <DialogSurface>  
          <DialogTitle>Settings Updated</DialogTitle>  
          <DialogContent>  
            Your settings have been successfully updated.  
          </DialogContent>  
          <DialogActions>  
            <Button onClick={closeDialog} appearance="primary">  
              OK  
            </Button>  
          </DialogActions>  
        </DialogSurface>  
      </Dialog>  
    </div>  
  );  
}  
  
export default SettingsPage;  
