// frontend/src/components/NetballCourt6Aside.js  
/**  
 * File Name: NetballCourt6Aside.js  
 * Location: frontend/src/components  
 * Description: Component for rendering a 6-aside netball court with corrected player positioning and shooting square.  
 */  
  
import React from 'react';  
import { makeStyles } from '@fluentui/react-components';  
import { mergeClasses } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  courtContainer: {  
    flexGrow: 1,  
    flexShrink: 1,  
    display: 'flex',  
    justifyContent: 'center',  
    alignItems: 'center',  
    marginRight: '20px',  
    '@media (max-width: 600px)': {  
      marginRight: '0',  
    },  
  },  
  courtWrapper: {  
    width: '100%',  
    maxHeight: '100%',  
    display: 'flex',  
    justifyContent: 'center',  
    alignItems: 'center',  
    overflow: 'auto',  
  },  
  courtSurround: {  
    position: 'relative',  
    width: '100%',  
    maxWidth: '700px',  
    aspectRatio: '1 / 2',  
    backgroundColor: '#F0F0F0', // Light grey background  
    padding: '20px',  
    boxSizing: 'border-box',  
  },  
  court: {  
    position: 'relative',  
    width: '100%',  
    height: '0',  
    paddingBottom: '200%',  
    backgroundColor: '#FFFFFF', // Court color  
    border: `2px solid #000000`,  
    overflow: 'hidden',  
  },  
  transverseLine: {  
    position: 'absolute',  
    left: '0',  
    width: '100%',  
    height: '2px',  
    backgroundColor: '#000000',  
  },  
  halfLine: {  
    top: '50%',  
  },  
  centerCircle: {  
    position: 'absolute',  
    left: '50%',  
    top: '50%',  
    width: '90px',  
    height: '90px',  
    border: `2px solid #000000`,  
    borderRadius: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  goalCircle: {  
    position: 'absolute',  
    width: '300px',  
    height: '300px',  
    border: `2px solid #000000`,  
    borderRadius: '50%',  
  },  
  goalCircleTop: {  
    top: '-150px',  
    left: '50%',  
    transform: 'translateX(-50%)',  
  },  
  goalCircleBottom: {  
    bottom: '-150px',  
    left: '50%',  
    transform: 'translateX(-50%)',  
  },  
  shootingSquare: {  
    position: 'absolute',  
    width: '100px',  
    height: '40px',  
    border: `2px solid #000000`,  
  },  
  shootingSquareTop: {  
    top: '8%',  
    left: '50%',  
    transform: 'translate(-50%, 0)',  
  },  
  shootingSquareBottom: {  
    bottom: '8%',  
    left: '50%',  
    transform: 'translate(-50%, 0)',  
  },  
  hoop: {  
    position: 'absolute',  
    width: '10px',  
    height: '10px',  
    backgroundColor: '#000000',  
    borderRadius: '50%',  
  },  
  hoopTop: {  
    top: '0%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  hoopBottom: {  
    bottom: '0%',  
    left: '50%',  
    transform: 'translate(-50%, 50%)',  
  },  
  position: {  
    // Increased size  
    width: '80px',  
    height: '80px',  
    minWidth: '60px',  
    minHeight: '60px',  
    backgroundColor: '#ADD8E6', // Light Blue for unassigned positions  
    color: '#000000',  
    borderRadius: '50%',  
    textAlign: 'center',  
    cursor: 'pointer',  
    position: 'absolute',  
    display: 'flex',  
    flexDirection: 'column',  
    justifyContent: 'center',  
    alignItems: 'center',  
    padding: '5px',  
    boxSizing: 'border-box',  
    border: '2px solid #000000',  
    '&:hover': {  
      backgroundColor: '#B0E0E6', // Slightly darker blue on hover  
    },  
  },  
  assigned: {  
    backgroundColor: '#98FB98', // Light pastel green for assigned positions  
  },  
  positionLabel: {  
    fontWeight: 'bold',  
    fontSize: '16px',  
    marginTop: '2px',  
  },  
  playerName: {  
    fontSize: '14px',  
    marginTop: '2px',  
    color: '#000000',  
  },  
  // Positions  
  positionA1: {  
    top: '10%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionA2: {  
    top: '25%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionC1: {  
    top: '45%',  
    left: '30%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionC2: {  
    top: '55%',  
    left: '70%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionD1: {  
    top: '75%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionD2: {  
    top: '90%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  playerStats: {  
    fontSize: '12px',  
    marginTop: '2px',  
    color: '#444444',  
  },  
});  
  
function NetballCourt6Aside({  
  assignedPlayers,  
  playerStats,  
  handlePlayerClick,  
}) {  
  const styles = useStyles();  
  const positions = ['A1', 'A2', 'C1', 'C2', 'D1', 'D2'];  
  
  return (  
    <div className={styles.courtContainer}>  
      <div className={styles.courtWrapper}>  
        <div className={styles.courtSurround}>  
          <div className={styles.court}>  
            {/* Transverse Line */}  
            <div className={`${styles.transverseLine} ${styles.halfLine}`}></div>  
  
            {/* Goal Circles */}  
            <div className={`${styles.goalCircle} ${styles.goalCircleTop}`}></div>  
            <div className={`${styles.goalCircle} ${styles.goalCircleBottom}`}></div>  
  
            {/* Shooting Squares */}  
            <div className={`${styles.shootingSquare} ${styles.shootingSquareTop}`}></div>  
            <div className={`${styles.shootingSquare} ${styles.shootingSquareBottom}`}></div>  
  
            {/* Hoop Dots */}  
            <div className={`${styles.hoop} ${styles.hoopTop}`}></div>  
            <div className={`${styles.hoop} ${styles.hoopBottom}`}></div>  
  
            {/* Centre Circle */}  
            <div className={styles.centerCircle}></div>  
  
            {/* Positions */}  
            {positions.map((position) => {  
              const positionStyle = styles[`position${position}`];  
              const playerName = assignedPlayers[position];  
              const stats = playerStats[playerName] || {};  
              return (  
                <div  
                  key={position}  
                  className={mergeClasses(  
                    styles.position,  
                    positionStyle,  
                    playerName ? styles.assigned : null  
                  )}  
                  onClick={() => handlePlayerClick(position)}  
                >  
                  <span className={styles.positionLabel}>{position}</span>  
                  <span className={styles.playerName}>  
                    {playerName ? playerName.split(' ')[0] : 'Assign'}  
                  </span>  
                  {stats.points ? (  
                    <span className={styles.playerStats}>  
                      Points: {stats.points}  
                    </span>  
                  ) : null}  
                </div>  
              );  
            })}  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
}  
  
export default NetballCourt6Aside;  
