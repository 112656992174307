// src/components/TeamPerformanceChart.js  
  
import React from 'react';  
import {  
  LineChart,  
  Line,  
  XAxis,  
  YAxis,  
  Tooltip,  
  Legend,  
  CartesianGrid,  
} from 'recharts';  
import PropTypes from 'prop-types';  
  
const TeamPerformanceChart = ({ data, gameType }) => {  
  if (!data || data.length === 0) {  
    return <p>No data available for {gameType}.</p>;  
  } 
  return (  
    <div>  
      <h3>Team Performance by Quarter ({gameType})</h3>  
      <LineChart width={600} height={300} data={data}> 
      <CartesianGrid stroke="#f5f5f5" />  
      <XAxis dataKey="quarter" />  
      <YAxis allowDecimals={false} />  
      <Tooltip />  
      <Legend />  
      <Line type="monotone" dataKey="teamPoints" name="Team Points" stroke="#8884d8" />  
      <Line type="monotone" dataKey="opponentPoints" name="Opponent Points" stroke="#82ca9d" />  
    </LineChart>  
  </div>  
);  
};


export default TeamPerformanceChart; 