// src/theme.js  
/**  
 * File Name: theme.js  
 * Location: src  
 * Description: Custom theme configuration with light and dark themes using a dark blue as primary color and golden yellow as accent color.  
 */  
  
import { createLightTheme, createDarkTheme } from '@fluentui/react-components';  
  
// Define custom brand colors using named shades (as per Fluent UI v9's expectations)  
const brandColors = {  
  10: '#0D47A1', // Dark Blue (Blue 900)  
  20: '#1565C0', // Blue 800  
  30: '#1976D2', // Blue 700  
  40: '#1E88E5', // Blue 600  
  50: '#2196F3', // Primary Blue (Blue 500)  
  60: '#42A5F5', // Blue 400  
  70: '#64B5F6', // Blue 300  
  80: '#90CAF9', // Blue 200  
  90: '#BBDEFB', // Blue 100  
  100: '#E3F2FD', // Light Blue (Blue 50)  
};  
  
// Define accent color  
const accentColor = '#FFB300'; // Golden Yellow (Amber 600)  
  
// Create base themes  
const lightBaseTheme = createLightTheme(brandColors);  
const darkBaseTheme = createDarkTheme(brandColors);  
  
// Custom tokens for light theme  
const lightCustomTokens = {  
  // Override tokens as needed  
  colorBrandBackground: brandColors[50],  
  colorBrandBackgroundHover: brandColors[40],  
  colorBrandBackgroundPressed: brandColors[30],  
  colorBrandBackgroundSelected: brandColors[40],  
  
  colorBrandForeground1: '#FFFFFF', // Ensure text on brand background is readable  
  colorBrandForegroundInverted: '#FFFFFF',  
  
  // Accent color overrides  
  colorPaletteAccentBackground: accentColor,  
  colorPaletteAccentForeground: '#FFFFFF',  
};  
  
// Custom tokens for dark theme  
const darkCustomTokens = {  
  // Override tokens as needed  
  colorBrandBackground: brandColors[50],  
  colorBrandBackgroundHover: brandColors[60],  
  colorBrandBackgroundPressed: brandColors[70],  
  colorBrandBackgroundSelected: brandColors[60],  
  
  colorBrandForeground1: '#FFFFFF', // Ensure text on brand background is readable  
  colorBrandForegroundInverted: '#FFFFFF',  
  
  // Accent color overrides  
  colorPaletteAccentBackground: accentColor,  
  colorPaletteAccentForeground: '#FFFFFF',  
};  
  
// Manually merge custom tokens into the base themes  
export const courtsideLightTheme = {  
  ...lightBaseTheme,  
  ...lightCustomTokens,  
};  
  
export const courtsideDarkTheme = {  
  ...darkBaseTheme,  
  ...darkCustomTokens,  
};  
