// src/utils/statistics.js  
/**  
 * File Name: statistics.js  
 * Location: src/utils  
 * Description: Utility functions for calculating statistics, updated for backward compatibility.  
 */  
  
 /**      
 * Calculates overall team statistics across all games.      
 * Separates statistics by game type (6-aside and 7-aside).      
 *      
 * @param {Array} eventsData - An array of event objects.      
 * @returns {Object} An object containing overall statistics for each game type.      
 */      
export const calculateOverallTeamStatistics = (eventsData) => {  
  const statsByGameType = {  
    '6-aside': {  
      goalsFor: 0,  
      totalShots: 0,  
      totalPointsFor: 0,  
      turnoversFor: 0,  
      turnoversAgainst: 0,  
      goalsAgainst: 0,  
      totalPointsAgainst: 0,  
      gamesPlayed: new Set(),  
    },  
    '7-aside': {  
      goalsFor: 0,  
      totalShots: 0,  
      totalPointsFor: 0,  
      turnoversFor: 0,  
      turnoversAgainst: 0,  
      goalsAgainst: 0,  
      totalPointsAgainst: 0,  
      gamesPlayed: new Set(),  
    },  
  };  
  
  eventsData.forEach((event) => {  
    const gameType = event.gameType || '7-aside';  
    const stats = statsByGameType[gameType];  
    const eventPoints = event.points || 1;  
    const gameId = event.gameId;  
    stats.gamesPlayed.add(gameId);  
  
    if (event.isSelectedTeamEvent) {  
      switch (event.eventType) {  
        case 'Goal':  
          stats.goalsFor += 1;  
          stats.totalShots += 1;  
          stats.totalPointsFor += eventPoints;  
          break;  
        case 'Shot':  
        case 'MissedShot':  
          stats.totalShots += 1;  
          break;  
        case 'TurnoverFor':  
          stats.turnoversFor += 1;  
          break;  
        case 'TurnoverAgainst':  
          stats.turnoversAgainst += 1;  
          break;  
        default:  
          break;  
      }  
    } else {  
      switch (event.eventType) {  
        case 'Goal':  
        case 'OpponentGoal':  
          stats.goalsAgainst += 1;  
          stats.totalPointsAgainst += eventPoints;  
          break;  
        case 'TurnoverFor':  
          stats.turnoversAgainst += 1;  
          break;  
        case 'TurnoverAgainst':  
          stats.turnoversFor += 1;  
          break;  
        default:  
          break;  
      }  
    }  
  });  
  
  // Calculate statistics for each game type  
  const overallStats = {};  
  
  for (const [gameType, stats] of Object.entries(statsByGameType)) {  
    const totalGames = stats.gamesPlayed.size;  
    const shootingPercentage =  
      stats.totalShots > 0 ? ((stats.goalsFor / stats.totalShots) * 100).toFixed(2) : '0.00';  
    const pointDifference = stats.totalPointsFor - stats.totalPointsAgainst;  
    const pointPercentage =  
      stats.totalPointsAgainst > 0  
        ? ((stats.totalPointsFor / stats.totalPointsAgainst) * 100).toFixed(2)  
        : 'N/A';  
  
    overallStats[gameType] = {  
      goalsFor: stats.goalsFor,  
      totalShots: stats.totalShots,  
      shootingPercentage,  
      turnoversFor: stats.turnoversFor,  
      turnoversAgainst: stats.turnoversAgainst,  
      goalsAgainst: stats.goalsAgainst,  
      totalPointsFor: stats.totalPointsFor,  
      totalPointsAgainst: stats.totalPointsAgainst,  
      totalGames,  
      pointDifference,  
      pointPercentage,  
    };  
  }  
  
  return overallStats;  
};   
    
/**    
 * Calculates team statistics by game type.    
 *    
 * @param {Array} eventsData - An array of event objects.    
 * @returns {Object} An object containing team statistics for each game type.    
 */    
export const calculateTeamStatisticsByGameType = (eventsData) => {      
  const statsByGameType = {};      
    
  ['6-aside', '7-aside'].forEach((gameType) => {      
    const filteredEvents = eventsData.filter((event) => event.gameType === gameType);      
    statsByGameType[gameType] = calculateTeamStatistics(filteredEvents);      
  });      
    
  // Add 'All' key    
  statsByGameType['All'] = calculateTeamStatistics(eventsData);    
    
  return statsByGameType;      
};    
    
    
/**      
 * Calculates player statistics by game type.      
 *      
 * @param {Array} eventsData - An array of event objects.      
 * @returns {Object} An object containing player statistics for each game type.      
 */      
export const calculatePlayerStatsByGameType = (eventsData) => {  
  const statsByGameType = {};  
  
  ['6-aside', '7-aside'].forEach((gameType) => {  
    const filteredEvents = eventsData.filter((event) => event.gameType === gameType);  
    statsByGameType[gameType] = calculatePlayerStatsWithPositions(filteredEvents);  
  });  
  
  // Include all events  
  statsByGameType['All'] = calculatePlayerStatsWithPositions(eventsData);  
  
  return statsByGameType;  
};    
    
/**    
 * Calculates team statistics.    
 *    
 * @param {Array} eventsData - An array of event objects.    
 * @returns {Object} An object containing team statistics.    
 */    
export const calculateTeamStatistics = (eventsData) => {    
  const stats = {    
    totalGoals: 0,    
    totalShots: 0,    
    shootingPercentage: 0,    
    turnoversFor: 0,    
    turnoversAgainst: 0,    
    opponentGoals: 0,    
    totalPoints: 0,    
    opponentPoints: 0,    
  };    
    
  eventsData.forEach((event) => {    
    const eventPoints = event.points || 1;    
    
    if (event.isSelectedTeamEvent) {    
      switch (event.eventType) {    
        case 'Goal':    
          stats.totalGoals += 1;    
          stats.totalShots += 1;    
          stats.totalPoints += eventPoints;    
          break;    
        case 'Shot':    
        case 'MissedShot':    
          stats.totalShots += 1;    
          break;    
        case 'TurnoverFor':    
          stats.turnoversFor += 1;    
          break;    
        case 'TurnoverAgainst':    
          stats.turnoversAgainst += 1;    
          break;    
        default:    
          break;    
      }    
    } else {    
      switch (event.eventType) {    
        case 'Goal':    
        case 'OpponentGoal':    
          stats.opponentGoals += 1;    
          stats.opponentPoints += eventPoints;    
          break;    
        case 'TurnoverFor':    
          stats.turnoversAgainst += 1;    
          break;    
        case 'TurnoverAgainst':    
          stats.turnoversFor += 1;    
          break;    
        default:    
          break;    
      }    
    }    
  });    
    
  stats.shootingPercentage =    
    stats.totalShots > 0 ? ((stats.totalGoals / stats.totalShots) * 100).toFixed(2) : '0.00';    
    
  return stats;    
};    
  
/**    
 * Calculates player statistics per game.    
 *      
 * @param {Array} eventsData - An array of event objects.    
 * @param {String} playerId - The ID of the player to calculate stats for.    
 * @returns {Object} An object where each key is a gameId with the player's statistics for that game.    
 */    
export const calculatePlayerStatsPerGame = (eventsData, playerId) => {    
  const statsPerGame = {};    
    
  eventsData.forEach((event) => {    
    if (event.playerId !== playerId) {    
      return;    
    }    
    
    const gameId = event.gameId;    
    
    if (!statsPerGame[gameId]) {    
      statsPerGame[gameId] = {    
        gameId,    
        totalGoals: 0,    
        totalShots: 0,    
        totalTurnovers: 0,    
        totalPoints: 0,    
        shootingPercentage: 0,    
        positionsPlayed: new Set(),    
        statsByPosition: {},    
        goalsByQuarter: {},    
        shotsByQuarter: {},    
        turnoversByQuarterAndPosition: {},    
      };    
    }    
    const stats = statsPerGame[gameId];    
    const eventPoints = event.points || 1;    
    const position = event.position || 'Unknown';    
    const quarter = event.quarter || 1;    
    
    // Initialize position stats if not already    
    if (!stats.statsByPosition[position]) {    
      stats.statsByPosition[position] = {    
        goals: 0,    
        shots: 0,    
        turnovers: 0,    
        points: 0,    
        shootingPercentage: 0,    
      };    
    }    
    
    const positionStats = stats.statsByPosition[position];    
    
    // Initialize quarterly stats    
    if (!stats.goalsByQuarter[quarter]) {    
      stats.goalsByQuarter[quarter] = {};    
    }    
    if (!stats.shotsByQuarter[quarter]) {    
      stats.shotsByQuarter[quarter] = {};    
    }    
    if (!stats.turnoversByQuarterAndPosition[quarter]) {    
      stats.turnoversByQuarterAndPosition[quarter] = {};    
    }    
    
    // Initialize position in quarterly stats    
    if (!stats.goalsByQuarter[quarter][position]) {    
      stats.goalsByQuarter[quarter][position] = 0;    
    }    
    if (!stats.shotsByQuarter[quarter][position]) {    
      stats.shotsByQuarter[quarter][position] = 0;    
    }    
    if (!stats.turnoversByQuarterAndPosition[quarter][position]) {    
      stats.turnoversByQuarterAndPosition[quarter][position] = 0;    
    }    
    
    switch (event.eventType) {    
      case 'Goal':    
        stats.totalGoals += 1;    
        stats.totalShots += 1;    
        stats.totalPoints += eventPoints;    
    
        positionStats.goals += 1;    
        positionStats.shots += 1;    
        positionStats.points += eventPoints;    
    
        stats.goalsByQuarter[quarter][position] += 1;    
        stats.shotsByQuarter[quarter][position] += 1;    
        break;    
      case 'MissedShot':    
        stats.totalShots += 1;    
    
        positionStats.shots += 1;    
    
        stats.shotsByQuarter[quarter][position] += 1;    
        break;    
      case 'TurnoverFor':    
      case 'TurnoverAgainst':    
        stats.totalTurnovers += 1;    
    
        positionStats.turnovers += 1;    
    
        stats.turnoversByQuarterAndPosition[quarter][position] += 1;    
        break;    
      default:    
        break;    
    }    
    
    stats.positionsPlayed.add(position);    
  });    
    
  // Calculate shooting percentages    
  for (const gameId in statsPerGame) {    
    const stats = statsPerGame[gameId];    
    stats.shootingPercentage =    
      stats.totalShots > 0    
        ? ((stats.totalGoals / stats.totalShots) * 100).toFixed(2)    
        : '0.00';    
    
    for (const position in stats.statsByPosition) {    
      const posStats = stats.statsByPosition[position];    
      posStats.shootingPercentage =    
        posStats.shots > 0 ? ((posStats.goals / posStats.shots) * 100).toFixed(2) : '0.00';    
    }    
  }    
    
  return statsPerGame;    
};    
  
/**      
 * Calculates average team performance per quarter, separated by game type.      
 * Excludes entire games where Q1 team or opponent points exceed a specified threshold.      
 *      
 * @param {Array} eventsData - An array of event objects.      
 * @param {Number} threshold - The maximum points allowed in Q1 to include the game in the calculation.      
 * @returns {Object} An object containing average performance data for each game type.      
 */      
export const calculateTeamPerformance = (eventsData, threshold = 20) => {  
  const performanceByGameType = {  
    '6-aside': [  
      { quarter: 'Q1', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q2', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q3', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q4', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
    ],  
    '7-aside': [  
      { quarter: 'Q1', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q2', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q3', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
      { quarter: 'Q4', teamPointsTotal: 0, opponentPointsTotal: 0, gameCount: 0 },  
    ],  
  };  
  
  // Group events by gameType and gameId  
  const eventsByGameTypeAndGameId = {};  
  
  eventsData.forEach((event) => {  
    const gameType = event.gameType || '7-aside';  
    const gameId = event.gameId;  
  
    if (!eventsByGameTypeAndGameId[gameType]) {  
      eventsByGameTypeAndGameId[gameType] = {};  
    }  
  
    if (!eventsByGameTypeAndGameId[gameType][gameId]) {  
      eventsByGameTypeAndGameId[gameType][gameId] = [];  
    }  
  
    eventsByGameTypeAndGameId[gameType][gameId].push(event);  
  });  
  
  // Process each game  
  Object.keys(eventsByGameTypeAndGameId).forEach((gameType) => {  
    const games = eventsByGameTypeAndGameId[gameType];  
  
    Object.keys(games).forEach((gameId) => {  
      const gameEvents = games[gameId];  
  
      // Initialize performance data for this game  
      const performancePerQuarter = [  
        { quarter: 'Q1', teamPoints: 0, opponentPoints: 0 },  
        { quarter: 'Q2', teamPoints: 0, opponentPoints: 0 },  
        { quarter: 'Q3', teamPoints: 0, opponentPoints: 0 },  
        { quarter: 'Q4', teamPoints: 0, opponentPoints: 0 },  
      ];  
  
      // Accumulate points per quarter for this game  
      gameEvents.forEach((event) => {  
        const quarter = event.quarter || 1;  
        const quarterIndex = quarter - 1;  
  
        if (quarter < 1 || quarter > 4) {  
          return; // Skip invalid quarters  
        }  
  
        const eventPoints = event.points || 1;  
  
        if (event.isSelectedTeamEvent) {  
          if (event.eventType === 'Goal') {  
            performancePerQuarter[quarterIndex].teamPoints += eventPoints;  
          }  
        } else {  
          if (event.eventType === 'Goal' || event.eventType === 'OpponentGoal') {  
            performancePerQuarter[quarterIndex].opponentPoints += eventPoints;  
          }  
        }  
      });  
  
      // Check if Q1 exceeds the threshold  
      const q1TeamPoints = performancePerQuarter[0].teamPoints;  
      const q1OpponentPoints = performancePerQuarter[0].opponentPoints;  
  
      if (q1TeamPoints > threshold || q1OpponentPoints > threshold) {  
        // Exclude this game entirely  
        return;  
      }  
  
      // Include this game's per-quarter data into overall performance data  
      for (let i = 0; i < 4; i++) {  
        const quarterData = performanceByGameType[gameType][i];  
        if (  
          performancePerQuarter[i].teamPoints > 0 ||  
          performancePerQuarter[i].opponentPoints > 0  
        ) {  
          quarterData.teamPointsTotal += performancePerQuarter[i].teamPoints;  
          quarterData.opponentPointsTotal += performancePerQuarter[i].opponentPoints;  
          quarterData.gameCount += 1;  
        }  
      }  
    });  
  });  
  
  // Calculate averages  
  const averagePerformanceByGameType = {};  
  
  Object.keys(performanceByGameType).forEach((gameType) => {  
    averagePerformanceByGameType[gameType] = performanceByGameType[gameType].map((quarterData) => {  
      const { quarter, teamPointsTotal, opponentPointsTotal, gameCount } = quarterData;  
      const averageTeamPoints =  
        gameCount > 0 ? parseFloat((teamPointsTotal / gameCount).toFixed(2)) : 0;  
      const averageOpponentPoints =  
        gameCount > 0 ? parseFloat((opponentPointsTotal / gameCount).toFixed(2)) : 0;  
  
      return {  
        quarter,  
        teamPoints: averageTeamPoints,  
        opponentPoints: averageOpponentPoints,  
      };  
    });  
  });  
  
  return averagePerformanceByGameType;  
};   
    
/**      
 * Calculates goals per game data, separated by game type.      
 *      
 * @param {Array} eventsData - An array of event objects.      
 * @param {Array} games - An array of game objects.      
 * @param {Function} getOpponentName - Function to get opponent name.      
 * @param {String} selectedTeamId - ID of the selected team.      
 * @returns {Object} An object containing goals per game data for each game type.      
 */      
export const calculateGoalsPerGame = (eventsData, games, getOpponentName, selectedTeamId) => {  
  const gameStatsByType = {  
    '6-aside': {},  
    '7-aside': {},  
  };  
  
  eventsData.forEach((event) => {  
    const gameId = event.gameId;  
    const gameType = event.gameType || '7-aside';  
  
    if (!gameStatsByType[gameType][gameId]) {  
      const game = games.find((g) => g.id === gameId);  
      const gameLabel = game  
        ? `${game.date} vs. ${getOpponentName(game, selectedTeamId)}`  
        : `Game ${gameId.slice(0, 5)}`;  
      gameStatsByType[gameType][gameId] = {  
        game: gameLabel,  
        teamPoints: 0,  
        opponentPoints: 0,  
      };  
    }  
  
    const gameStats = gameStatsByType[gameType][gameId];  
    const eventPoints = event.points || 1;  
  
    if (event.isSelectedTeamEvent) {  
      if (event.eventType === 'Goal') {  
        gameStats.teamPoints += eventPoints;  
      }  
    } else {  
      if (event.eventType === 'Goal' || event.eventType === 'OpponentGoal') {  
        gameStats.opponentPoints += eventPoints;  
      }  
    }  
  });  
  
  // Prepare data for charts  
  const goalsPerGameData6Aside = Object.values(gameStatsByType['6-aside']);  
  const goalsPerGameData7Aside = Object.values(gameStatsByType['7-aside']);  
  
  return {  
    '6-aside': goalsPerGameData6Aside,  
    '7-aside': goalsPerGameData7Aside,  
  };  
};    
    
/**    
  * Calculates quarterly data for a specific player, including positions played.    
  *    
  * @param {Object} playerStatistics - The statistics object for the player.    
  * @returns {Array} An array of quarterly data.    
  */    
export const calculateQuarterlyData = (playerStatistics) => {    
  const data = [];    
    
  if (playerStatistics && playerStatistics.shotsByQuarter) {    
    Object.keys(playerStatistics.shotsByQuarter).forEach((quarter) => {    
      const positionsInQuarter = new Set([    
        ...Object.keys(playerStatistics.shotsByQuarter[quarter]),    
        ...(playerStatistics.goalsByQuarter[quarter]    
          ? Object.keys(playerStatistics.goalsByQuarter[quarter])    
          : []),    
        ...(playerStatistics.turnoversByQuarterAndPosition[quarter]    
          ? Object.keys(playerStatistics.turnoversByQuarterAndPosition[quarter])    
          : []),    
      ]);    
    
      positionsInQuarter.forEach((position) => {    
        const shots = playerStatistics.shotsByQuarter[quarter][position] || 0;    
        const goals = playerStatistics.goalsByQuarter[quarter][position] || 0;    
        const turnovers =    
          (playerStatistics.turnoversByQuarterAndPosition[quarter] &&    
            playerStatistics.turnoversByQuarterAndPosition[quarter][position]) ||    
          0;    
        const shootingPercentage = shots > 0 ? ((goals / shots) * 100).toFixed(2) : '0.00';    
    
        data.push({    
          quarter,    
          position,    
          shots,    
          goals,    
          turnovers,    
          shootingPercentage,    
        });    
      });    
    });    
  }    
    
  return data;    
};    
  
/**    
 * Calculates player statistics, including positions played, handling backward compatibility.    
 *    
 * @param {Array} eventsData - An array of event objects.    
 * @param {String} [playerId] - (Optional) The ID of the player to calculate stats for.    
 * @returns {Array|Object} An array of player statistics or an object if playerId is provided.    
 */    
export const calculatePlayerStatsWithPositions = (eventsData, playerId = null, teamId = null) => {    
  const stats = {};    
    
  eventsData.forEach((event) => {    
    const playerName = event.playerName;    
    const eventPlayerId = event.playerId;    
    const eventTeamId = event.teamId;    
    const position = event.position || 'Unknown';    
    const quarter = event.quarter || 1;    
    
    if (!playerName) return;    
    if (playerId && eventPlayerId !== playerId) return;    
    if (teamId && eventTeamId !== teamId) return;    
    
    if (!stats[playerName]) {    
      stats[playerName] = {    
        playerId: eventPlayerId,    
        totalGoals: 0,    
        totalShots: 0,    
        totalTurnovers: 0,    
        totalPoints: 0,    
        shootingPercentage: 0,    
        positionsPlayed: new Set(),    
        statsByPosition: {},    
        goalsByQuarter: {},    
        shotsByQuarter: {},    
        turnoversByQuarterAndPosition: {},    
      };    
    }    
    
    const playerStats = stats[playerName];    
    const eventPoints = event.points || 1;    
    
    // Initialize position stats if not already    
    if (!playerStats.statsByPosition[position]) {    
      playerStats.statsByPosition[position] = {    
        goals: 0,    
        shots: 0,    
        turnovers: 0,    
        points: 0,    
        shootingPercentage: 0,    
      };    
    }    
    
    const positionStats = playerStats.statsByPosition[position];    
    
    // Initialize quarterly stats    
    if (!playerStats.goalsByQuarter[quarter]) {    
      playerStats.goalsByQuarter[quarter] = {};    
    }    
    if (!playerStats.shotsByQuarter[quarter]) {    
      playerStats.shotsByQuarter[quarter] = {};    
    }    
    if (!playerStats.turnoversByQuarterAndPosition[quarter]) {    
      playerStats.turnoversByQuarterAndPosition[quarter] = {};    
    }    
    
    // Initialize position in quarterly stats    
    if (!playerStats.goalsByQuarter[quarter][position]) {    
      playerStats.goalsByQuarter[quarter][position] = 0;    
    }    
    if (!playerStats.shotsByQuarter[quarter][position]) {    
      playerStats.shotsByQuarter[quarter][position] = 0;    
    }    
    if (!playerStats.turnoversByQuarterAndPosition[quarter][position]) {    
      playerStats.turnoversByQuarterAndPosition[quarter][position] = 0;    
    }    
    
    switch (event.eventType) {    
      case 'Goal':    
        playerStats.totalGoals += 1;    
        playerStats.totalShots += 1;    
        playerStats.totalPoints += eventPoints;    
    
        positionStats.goals += 1;    
        positionStats.shots += 1;    
        positionStats.points += eventPoints;    
    
        playerStats.goalsByQuarter[quarter][position] += 1;    
        playerStats.shotsByQuarter[quarter][position] += 1;    
        break;    
      case 'Shot':    
      case 'MissedShot':    
        playerStats.totalShots += 1;    
    
        positionStats.shots += 1;    
    
        playerStats.shotsByQuarter[quarter][position] += 1;    
        break;    
      case 'TurnoverFor':    
      case 'TurnoverAgainst':    
        playerStats.totalTurnovers += 1;    
    
        positionStats.turnovers += 1;    
    
        playerStats.turnoversByQuarterAndPosition[quarter][position] += 1;    
        break;    
      default:    
        break;    
    }    
    
    playerStats.positionsPlayed.add(position);    
  });    
    
  // Calculate shooting percentages    
  for (const playerName in stats) {    
    const playerStats = stats[playerName];    
    playerStats.shootingPercentage =    
      playerStats.totalShots > 0    
        ? ((playerStats.totalGoals / playerStats.totalShots) * 100).toFixed(2)    
        : '0.00';    
    
    for (const position in playerStats.statsByPosition) {    
      const posStats = playerStats.statsByPosition[position];    
      posStats.shootingPercentage =    
        posStats.shots > 0 ? ((posStats.goals / posStats.shots) * 100).toFixed(2) : '0.00';    
    }    
  }    
    
  if (playerId) {    
    // Return stats for the specific player    
    return stats[Object.keys(stats)[0]] || {};    
  } else {    
    // Return array of all player stats    
    return Object.keys(stats).map((playerName) => ({    
      playerName,    
      ...stats[playerName],    
    }));    
  }    
};    

