// frontend/src/pages/ManageTeamsPage.js  
/**    
 * File Name: ManageTeamsPage.js    
 * Location: src/pages    
 * Description: Component for managing teams.    
 */  
  
import React, { useEffect, useState } from 'react';  
import {  
  Button,  
  Table,  
  TableHeader,  
  TableRow,  
  TableCell,  
  TableBody,  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  makeStyles,  
  tokens,  
  shorthands,  
} from '@fluentui/react-components';  
import { useNavigate } from 'react-router-dom';  
import TeamController from '../controllers/TeamController';  
  
const useStyles = makeStyles({  
  container: {  
    ...shorthands.padding('20px'),  
  },  
  addButton: {  
    marginBottom: '20px',  
  },  
  actionButtons: {  
    display: 'flex',  
    gap: '10px',  
  },  
});  
  
function ManageTeamsPage() {  
  const navigate = useNavigate();  
  const [teams, setTeams] = useState([]);  
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);  
  const [teamToDelete, setTeamToDelete] = useState(null);  
  const styles = useStyles();  
  
  useEffect(() => {  
    fetchTeams();  
  }, []);  
  
  const fetchTeams = async () => {  
    try {  
      const teamsData = await TeamController.getAllTeams();  
      setTeams(teamsData);  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
    }  
  };  
  
  const handleEdit = (id) => {  
    navigate('/add_edit_team', { state: { id } });  
  };  
  
  const handleDeleteClick = (team) => {  
    setTeamToDelete(team);  
    setDeleteConfirmOpen(true);  
  };  
  
  const handleDeleteConfirm = async () => {  
    try {  
      await TeamController.deleteTeam(teamToDelete.id);  
      fetchTeams();  
      setDeleteConfirmOpen(false);  
    } catch (error) {  
      console.error('Error deleting team:', error);  
    }  
  };  
  
  return (  
    <div className={styles.container}>  
      <h2>Manage Teams</h2>  
      <Button  
        onClick={() => navigate('/add_edit_team')}  
        appearance="primary"  
        className={styles.addButton}  
      >  
        Add New Team  
      </Button>  
      <Table>  
        <TableHeader>  
          <TableRow>  
            <TableCell>Name</TableCell>  
            <TableCell>Division</TableCell>  
            <TableCell>Actions</TableCell>  
          </TableRow>  
        </TableHeader>  
        <TableBody>  
          {teams.map((team) => (  
            <TableRow key={team.id}>  
              <TableCell>{team.name}</TableCell>  
              <TableCell>{team.division}</TableCell>  
              <TableCell>  
                <div className={styles.actionButtons}>  
                  <Button onClick={() => handleEdit(team.id)}>Edit</Button>  
                  <Button onClick={() => handleDeleteClick(team)} appearance="secondary">  
                    Delete  
                  </Button>  
                </div>  
              </TableCell>  
            </TableRow>  
          ))}  
        </TableBody>  
      </Table>  
  
      {/* Delete Confirmation Dialog */}  
      {deleteConfirmOpen && (  
        <Dialog  
          open={deleteConfirmOpen}  
          onOpenChange={() => setDeleteConfirmOpen(false)}  
        >  
          <DialogSurface>  
            <DialogTitle>Delete Team</DialogTitle>  
            <DialogContent>  
              Are you sure you want to delete {teamToDelete?.name}?  
            </DialogContent>  
            <DialogActions>  
              <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>  
              <Button  
                onClick={handleDeleteConfirm}  
                appearance="primary"  
                style={{ backgroundColor: tokens.colorPaletteRedBackground3 }}  
              >  
                Delete  
              </Button>  
            </DialogActions>  
          </DialogSurface>  
        </Dialog>  
      )}  
    </div>  
  );  
}  
  
export default ManageTeamsPage;  
