/**  
 * File Name: Bench.js  
 * Location: src/components  
 * Description: Component to display bench players.  
 */  
  
import React from 'react';  
import { makeStyles } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  benchContainer: {  
    marginTop: '20px',  
  },  
  benchTitle: {  
    marginTop: '20px',  
  },  
  benchList: {  
    listStyle: 'none',  
    padding: '0',  
  },  
  benchPlayer: {  
    padding: '5px 0',  
  },  
});  
  
const Bench = ({ benchPlayers }) => {  
  const styles = useStyles();  
  
  return (  
    <div className={styles.benchContainer}>  
      <h4 className={styles.benchTitle}>Bench</h4>  
      <ul className={styles.benchList}>  
        {benchPlayers.map((playerName, index) => (  
          <li key={index} className={styles.benchPlayer}>  
            {playerName}  
          </li>  
        ))}  
      </ul>  
    </div>  
  );  
};  
  
export default Bench;  
