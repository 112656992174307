// frontend/src/components/GameClock.js  
/**  
 * File Name: GameClock.js  
 * Location: frontend/src/components  
 * Description: Component for displaying and controlling the game clock.  
 */  
  
import React from 'react';  
import { Button, makeStyles } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  clockDisplay: {  
    fontSize: '2rem',  
    marginBottom: '10px',  
    textAlign: 'center',  
  },  
  buttonGroup: {  
    display: 'flex',  
    flexWrap: 'wrap',  
    gap: '10px',  
    justifyContent: 'center',  
  },  
});  
  
function GameClock({ time, isRunning, onStart, onPause, onReset, onAddTime }) {  
  const styles = useStyles();  
  
  const formatTime = (seconds) => {  
    const mins = Math.floor(seconds / 60).toString().padStart(2, '0');  
    const secs = (seconds % 60).toString().padStart(2, '0');  
    return `${mins}:${secs}`;  
  };  
  
  return (  
    <div>  
      <div className={styles.clockDisplay}>{formatTime(time)}</div>  
      <div className={styles.buttonGroup}>  
        {isRunning ? (  
          <Button onClick={onPause}>Pause</Button>  
        ) : (  
          <Button onClick={onStart}>Start</Button>  
        )}  
        <Button onClick={() => onAddTime(60)}>+1 min</Button>  
        <Button onClick={() => onAddTime(-60)} disabled={time < 60}>  
          -1 min  
        </Button>  
        <Button onClick={onReset}>Reset</Button>  
      </div>  
    </div>  
  );  
}  
  
export default GameClock;  
