// frontend/src/components/Layout.js  
  
import React, { useContext } from 'react';  
import { Outlet } from 'react-router-dom';  
import Navbar from './Navbar';  
import Footer from './Footer';  
import ConnectionStatusBar from './ConnectionStatusBar';  
import { makeStyles, tokens, shorthands } from '@fluentui/react-components';  
import { AuthContext } from '../contexts/AuthContext';  
  
const useStyles = makeStyles({  
  mainContent: {  
    ...shorthands.padding('20px'),  
    backgroundColor: tokens.colorNeutralBackground1,  
    minHeight: 'calc(100vh - 150px)',  
  },  
});  
  
function Layout({ onThemeChange, currentTheme }) {  
  const styles = useStyles();  
  const { user } = useContext(AuthContext);  
  
  return (  
    <>  
      <Navbar  
        onThemeChange={onThemeChange}  
        currentTheme={currentTheme}  
        user={user}  
      />  
      {/* Include the ConnectionStatusBar here */}  
      <ConnectionStatusBar />  
      <main className={styles.mainContent}>  
        <Outlet />  
      </main>  
      <Footer />  
    </>  
  );  
}  
  
export default Layout;  
