/**  
 * File Name: ActionButtons.js  
 * Location: src/components  
 * Description: Component to display action buttons for recording events.  
 */  
  
import React from 'react';  
import { Button } from '@fluentui/react-components';  
import { mergeClasses } from '@fluentui/react-components';  
  
const ActionButtons = ({  
  gameType,  
  positions,  
  assignedPlayers,  
  recordEvent,  
  styles,  
  positionOptions,  
  setIsEventLogOpen,  
}) => {  
  return (  
    <>  
      {/* Goal and Shot Buttons */}  
      {gameType === '7-aside' &&  
        ['GA', 'GS'].map((position) => (  
          <div key={position} className={styles.buttonRow}>  
            <Button  
              className={mergeClasses(  
                styles.actionButton,  
                styles.goalButton,  
                styles[`row${position}Button`]  
              )}  
              onClick={() => recordEvent('Goal', position, 1)}  
            >  
              {(assignedPlayers[position]?.split(' ')[0] || position)} Goal  
            </Button>  
            <Button  
              className={mergeClasses(  
                styles.actionButton,  
                styles.missedShotButton,  
                styles[`row${position}Button`]  
              )}  
              onClick={() => recordEvent('MissedShot', position)}  
            >  
              {(assignedPlayers[position]?.split(' ')[0] || position)} Missed Shot  
            </Button>  
          </div>  
        ))}  
  
      {gameType === '6-aside' &&  
        ['A1', 'A2', 'C1', 'C2'].map((position) => (  
          <div key={position} className={styles.buttonRow}>  
            {positionOptions[position].map((option) => (  
              <Button  
                key={`${position}-${option.label}`}  
                className={mergeClasses(  
                  styles.actionButton,  
                  option.type === 'Goal'  
                    ? styles.goalButton  
                    : styles.missedShotButton,  
                  styles[`row${position}Button`]  
                )}  
                onClick={() =>  
                  recordEvent(option.type, position, option.points)  
                }  
              >  
                {(assignedPlayers[position]?.split(' ')[0] || position)}{' '}  
                {option.label}  
              </Button>  
            ))}  
          </div>  
        ))}  
  
      {/* Turnover and Utility Buttons */}  
      <div className={styles.buttonRow}>  
        {gameType === '6-aside' ? (  
          // For 6-aside games, show both 1-point and 2-point opponent goal buttons  
          <>  
            <Button  
              className={mergeClasses(styles.actionButton, styles.utilButton)}  
              onClick={() => recordEvent('OpponentGoal', null, 1)}  
            >  
              Opponent 1 Point Goal  
            </Button>  
            <Button  
              className={mergeClasses(styles.actionButton, styles.utilButton)}  
              onClick={() => recordEvent('OpponentGoal', null, 2)}  
            >  
              Opponent 2 Point Goal  
            </Button>  
          </>  
        ) : (  
          // For 7-aside games, show only a single opponent goal button with 1 point  
          <Button  
            className={mergeClasses(styles.actionButton, styles.utilButton)}  
            onClick={() => recordEvent('OpponentGoal', null, 1)}  
          >  
            Opponent Goal  
          </Button>  
        )}  
      </div>  
      <div className={styles.buttonRow}>  
        <Button  
          className={mergeClasses(styles.actionButton, styles.utilButton)}  
          onClick={() => recordEvent('TurnoverFor')}  
        >  
          Turnover For  
        </Button>  
        <Button  
          className={mergeClasses(styles.actionButton, styles.utilButton)}  
          onClick={() => recordEvent('TurnoverAgainst')}  
        >  
          Turnover Against  
        </Button>  
        <Button  
          className={mergeClasses(styles.actionButton, styles.utilButton)}  
          onClick={() => setIsEventLogOpen(true)}  
        >  
          View Event Log  
        </Button>  
      </div>  
    </>  
  );  
};  
  
export default ActionButtons;  
