// frontend/src/components/NetballCourt7Aside.js  
/**  
 * File Name: NetballCourt7Aside.js  
 * Location: frontend/src/components  
 * Description: Component for rendering a 7-a-side netball court with corrected player positioning.  
 */  
  
import React from 'react';  
import { makeStyles } from '@fluentui/react-components';  
import { mergeClasses } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  courtContainer: {  
    flexBasis: 'calc(100% - 300px)',  
    display: 'flex',  
    justifyContent: 'center',  
    alignItems: 'center',  
    marginRight: '20px',  
    '@media (max-width: 600px)': {  
      flexBasis: '100%',  
      marginRight: '0',  
    },  
  },  
  courtWrapper: {  
    width: '100%',  
    maxHeight: '100%',  
    display: 'flex',  
    justifyContent: 'center',  
    alignItems: 'center',  
    overflow: 'auto',  
  },  
  courtSurround: {  
    position: 'relative',  
    width: '100%',  
    maxWidth: '700px',  
    aspectRatio: '1 / 2',  
    backgroundColor: '#F0F0F0', // Light grey background  
    padding: '20px',  
    boxSizing: 'border-box',  
  },  
  court: {  
    position: 'relative',  
    width: '100%',  
    height: '0',  
    paddingBottom: '200%',  
    backgroundColor: '#FFFFFF', // Court color  
    border: `2px solid #000000`,  
    overflow: 'hidden',  
  },  
  transverseLine: {  
    position: 'absolute',  
    left: '0',  
    width: '100%',  
    height: '2px',  
    backgroundColor: '#000000',  
  },  
  topThird: {  
    top: '33.33%',  
  },  
  bottomThird: {  
    top: '66.66%',  
  },  
  centerCircle: {  
    position: 'absolute',  
    left: '50%',  
    top: '50%',  
    width: '90px',  
    height: '90px',  
    border: `2px solid #000000`,  
    borderRadius: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  goalCircle: {  
    position: 'absolute',  
    width: '300px',  
    height: '300px',  
    border: `2px solid #000000`,  
    borderRadius: '50%',  
  },  
  goalCircleTop: {  
    top: '-150px',  
    left: '50%',  
    transform: 'translateX(-50%)',  
  },  
  goalCircleBottom: {  
    bottom: '-150px',  
    left: '50%',  
    transform: 'translateX(-50%)',  
  },  
  hoop: {  
    position: 'absolute',  
    width: '10px',  
    height: '10px',  
    backgroundColor: '#000000',  
    borderRadius: '50%',  
  },  
  hoopTop: {  
    top: '0%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  hoopBottom: {  
    bottom: '0%',  
    left: '50%',  
    transform: 'translate(-50%, 50%)',  
  },  
  position: {  
    // Increased size  
    width: '80px',  
    height: '80px',  
    minWidth: '60px',  
    minHeight: '60px',  
    backgroundColor: '#ADD8E6', // Light Blue for unassigned positions  
    color: '#000000',  
    borderRadius: '50%',  
    textAlign: 'center',  
    cursor: 'pointer',  
    position: 'absolute',  
    display: 'flex',  
    flexDirection: 'column',  
    justifyContent: 'center',  
    alignItems: 'center',  
    padding: '5px',  
    boxSizing: 'border-box',  
    border: '2px solid #000000',  
    '&:hover': {  
      backgroundColor: '#B0E0E6', // Slightly darker blue on hover  
    },  
  },  
  assigned: {  
    backgroundColor: '#98FB98', // Light pastel green for assigned positions  
  },  
  positionLabel: {  
    fontWeight: 'bold',  
    fontSize: '16px',  
    marginTop: '2px',  
  },  
  playerName: {  
    fontSize: '14px',  
    marginTop: '2px',  
    color: '#000000',  
  },  
  // Adjusted positions to match accurate netball court positions  
  positionGS: {  
    top: '10%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionGA: {  
    top: '25%',  
    left: '75%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionWA: {  
    top: '40%',  
    left: '25%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionC: {  
    top: '50%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionWD: {  
    top: '60%',  
    left: '75%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionGD: {  
    top: '75%',  
    left: '25%',  
    transform: 'translate(-50%, -50%)',  
  },  
  positionGK: {  
    top: '90%',  
    left: '50%',  
    transform: 'translate(-50%, -50%)',  
  },  
  playerStats: {  
    fontSize: '12px',  
    marginTop: '2px',  
    color: '#444444',  
  },  
});  
  
function NetballCourt7Aside({  
  assignedPlayers,  
  playerStats,  
  handlePlayerClick,  
}) {  
  const styles = useStyles();  
  const positions = ['GS', 'GA', 'WA', 'C', 'WD', 'GD', 'GK'];  
  
  return (  
    <div className={styles.courtContainer}>  
      <div className={styles.courtWrapper}>  
        <div className={styles.courtSurround}>  
          <div className={styles.court}>  
            {/* Transverse Lines */}  
            <div className={`${styles.transverseLine} ${styles.topThird}`}></div>  
            <div className={`${styles.transverseLine} ${styles.bottomThird}`}></div>  
  
            {/* Goal Circles */}  
            <div className={`${styles.goalCircle} ${styles.goalCircleTop}`}></div>  
            <div className={`${styles.goalCircle} ${styles.goalCircleBottom}`}></div>  
  
            {/* Hoop Dots */}  
            <div className={`${styles.hoop} ${styles.hoopTop}`}></div>  
            <div className={`${styles.hoop} ${styles.hoopBottom}`}></div>  
  
            {/* Centre Circle */}  
            <div className={styles.centerCircle}></div>  
  
            {/* Positions */}  
            {positions.map((position) => {  
              const positionStyle = styles[`position${position}`];  
              const playerName = assignedPlayers[position];  
              const stats = playerStats[playerName] || {};  
              return (  
                <div  
                  key={position}  
                  className={mergeClasses(  
                    styles.position,  
                    positionStyle,  
                    playerName ? styles.assigned : null  
                  )}  
                  onClick={() => handlePlayerClick(position)}  
                >  
                  <span className={styles.positionLabel}>{position}</span>  
                  <span className={styles.playerName}>  
                    {playerName ? playerName.split(' ')[0] : 'Assign'}  
                  </span>  
                  {stats.goals ? (  
                    <span className={styles.playerStats}>  
                      Goals: {stats.goals}  
                    </span>  
                  ) : null}  
                </div>  
              );  
            })}  
          </div>  
        </div>  
      </div>  
    </div>  
  );  
}  
  
export default NetballCourt7Aside;  
