// frontend/src/controllers/PlayerController.js  
/**    
 * File Name: PlayerController.js    
 * Location: frontend/src/controllers    
 * Description: Controller handling player-related data operations with backend integration.    
 */  
  
import api from '../api';  
  
class PlayerController {  
  // Fetch all players from the backend  
  async getAllPlayers() {  
    try {  
      const response = await api.get('/players');  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching players:', error);  
      throw error;  
    }  
  }  
  
  // Fetch a player by ID  
  async getPlayerById(id) {  
    try {  
      const response = await api.get(`/players/${id}`);  
      return response.data;  
    } catch (error) {  
      console.error(`Error fetching player with id ${id}:`, error);  
      throw error;  
    }  
  }  
  
  // Add a new player  
  async addPlayer(player) {  
    try {  
      const response = await api.post('/players', player);  
      return response.data;  
    } catch (error) {  
      console.error('Error adding player:', error);  
      throw error;  
    }  
  }  
  
  // Update an existing player  
  async updatePlayer(player) {  
    try {  
      const response = await api.put(`/players/${player.id}`, player);  
      return response.data;  
    } catch (error) {  
      console.error('Error updating player:', error);  
      throw error;  
    }  
  }  
  
  // Delete a player  
  async deletePlayer(id) {  
    try {  
      await api.delete(`/players/${id}`);  
    } catch (error) {  
      console.error('Error deleting player:', error);  
      throw error;  
    }  
  }  
}  
  
const playerControllerInstance = new PlayerController();  
export default playerControllerInstance;  
