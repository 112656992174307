// frontend/src/controllers/GameController.js  
/**  
 * File Name: GameController.js  
 * Location: frontend/src/controllers  
 * Description: Controller handling game-related data operations with backend integration, now includes fetching games by team ID.  
 */  
  
import api from '../api';  
  
class GameController {  
  // Fetch all games from the backend  
  async getAllGames() {  
    try {  
      const response = await api.get('/games');  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching games:', error);  
      throw error;  
    }  
  }  
  
  // Fetch a game by ID  
  async getGameById(id) {  
    try {  
      const response = await api.get(`/games/${id}`);  
      return response.data;  
    } catch (error) {  
      console.error(`Error fetching game with id ${id}:`, error);  
      throw error;  
    }  
  }  
  
  // Add a new game  
  async addGame(game) {  
    try {  
      const response = await api.post('/games', game);  
      return response.data;  
    } catch (error) {  
      console.error('Error adding game:', error);  
      throw error;  
    }  
  }  
  
  // Update an existing game  
  async updateGame(game) {  
    try {  
      const response = await api.put(`/games/${game.id}`, game);  
      return response.data;  
    } catch (error) {  
      console.error('Error updating game:', error);  
      throw error;  
    }  
  }  
  
  // Delete a game  
  async deleteGame(id) {  
    try {  
      await api.delete(`/games/${id}`);  
    } catch (error) {  
      console.error('Error deleting game:', error);  
      throw error;  
    }  
  }  
  
  // Fetch games by team ID  
  async getGamesByTeamId(teamId) {  
    try {  
      const response = await api.get(`/games/team/${teamId}`);  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching games for team:', error);  
      throw error;  
    }  
  }  
}  
  
const gameControllerInstance = new GameController();  
export default gameControllerInstance;  
