/**  
 * File Name: usePlayersData.js  
 * Location: src/hooks  
 * Description: Custom hook to manage player data and assignments.  
 */  
  
import { useState, useEffect } from 'react';  
import PlayerController from '../controllers/PlayerController';  
  
const usePlayersData = () => {  
  const [players, setPlayers] = useState([]);  
  const [playerNameToIdMap, setPlayerNameToIdMap] = useState({});  
  const [benchPlayers, setBenchPlayers] = useState([]);  
  const [assignedPlayers, setAssignedPlayers] = useState({});  
  const [assignedPlayerIds, setAssignedPlayerIds] = useState({});  
  
  useEffect(() => {  
    fetchPlayers();  
  }, []);  
  
  const fetchPlayers = async () => {  
    try {  
      const allPlayers = await PlayerController.getAllPlayers();  
      setPlayers(allPlayers);  
  
      // Initialize bench with all players  
      const initialBench = allPlayers.map((player) => player.name);  
      setBenchPlayers(initialBench);  
  
      // Create a mapping from player name to player ID  
      const nameToIdMap = {};  
      allPlayers.forEach((player) => {  
        nameToIdMap[player.name] = player.id;  
      });  
      setPlayerNameToIdMap(nameToIdMap);  
    } catch (error) {  
      console.error('Error fetching players:', error);  
    }  
  };  
  
  const assignPlayer = (position, playerName, playerId) => {  
    setAssignedPlayers((prev) => ({  
      ...prev,  
      [position]: playerName,  
    }));  
  
    setAssignedPlayerIds((prev) => ({  
      ...prev,  
      [position]: playerId,  
    }));  
  
    setBenchPlayers((prev) => prev.filter((name) => name !== playerName));  
  };  
  
  const unassignPlayer = (position) => {  
    const playerName = assignedPlayers[position];  
    if (playerName) {  
      setAssignedPlayers((prev) => {  
        const updated = { ...prev };  
        delete updated[position];  
        return updated;  
      });  
      setAssignedPlayerIds((prev) => {  
        const updated = { ...prev };  
        delete updated[position];  
        return updated;  
      });  
      setBenchPlayers((prev) => [...prev, playerName]);  
    }  
  };  
  
  return {  
    players,  
    benchPlayers,  
    setBenchPlayers,  
    assignedPlayers,  
    assignedPlayerIds,  
    playerNameToIdMap,  
    assignPlayer,  
    unassignPlayer,  
  };  
};  
  
export default usePlayersData;  
