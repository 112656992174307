// frontend/src/pages/AddEditPlayerPage.js  
/**  
 * File Name: AddEditPlayerPage.js  
 * Location: src/pages  
 * Description: Component for adding or editing a player's details, including required 'number' field.  
 */  
  
import React, { useState, useEffect } from 'react';  
import {  
  Button,  
  Input,  
  Label,  
  Combobox,  
  Option,  
  makeStyles,  
  shorthands,  
} from '@fluentui/react-components';  
import PlayerController from '../controllers/PlayerController';  
import TeamController from '../controllers/TeamController';  
import { useNavigate, useLocation } from 'react-router-dom';  
  
const useStyles = makeStyles({  
  container: {  
    ...shorthands.padding('20px'),  
    maxWidth: '600px',  
    margin: '0 auto',  
  },  
  formField: {  
    marginBottom: '15px',  
  },  
});  
  
function AddEditPlayerPage() {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const [playerId, setPlayerId] = useState(null);  
  const [playerName, setPlayerName] = useState('');  
  const [playerPosition, setPlayerPosition] = useState('');  
  const [teamId, setTeamId] = useState('');  
  const [teamName, setTeamName] = useState('');  
  const [teams, setTeams] = useState([]); // State to hold teams  
  const [playerNumber, setPlayerNumber] = useState(''); // New state for player number  
  const styles = useStyles();  
  
  useEffect(() => {  
    fetchTeams();  
    if (location.state && location.state.id) {  
      fetchPlayer(location.state.id);  
    }  
  }, [location.state]);  
  
  const fetchTeams = async () => {  
    try {  
      const teamsData = await TeamController.getAllTeams();  
      setTeams(teamsData);  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
    }  
  };  
  
  const fetchPlayer = async (id) => {  
    try {  
      const player = await PlayerController.getPlayerById(id);  
      if (player) {  
        setPlayerId(player.id);  
        setPlayerName(player.name);  
        setPlayerPosition('C');  
        setTeamId(player.teamId || '');  
        setPlayerNumber('9999999999');  
        // Set teamName based on teamId  
        const team = teams.find((t) => t.id === player.teamId);  
        setTeamName(team ? team.name : '');  
      }  
    } catch (error) {  
      console.error('Error fetching player:', error);  
    }  
  };  
  
  const handleSave = async (event) => {  
    event.preventDefault();  
    const playerData = {  
      id: playerId || undefined,  
      name: playerName,  
      position: 'C',  
      number: '999999999',  
      teamId: teamId,  
    };  
  
    try {  
      if (playerId) {  
        // Update existing player  
        await PlayerController.updatePlayer(playerData);  
      } else {  
        // Add new player  
        await PlayerController.addPlayer(playerData);  
      }  
      navigate('/manage_players');  
    } catch (error) {  
      console.error('Error saving player:', error);  
    }  
  };  
  
  const handleTeamSelection = (event, data) => {  
    setTeamId(data.optionValue);  
    const selectedTeam = teams.find((team) => team.id === data.optionValue);  
    setTeamName(selectedTeam ? selectedTeam.name : '');  
  };  
  
  return (  
    <div className={styles.container}>  
      <h2>{playerId ? 'Edit Player' : 'Add Player'}</h2>  
      <form onSubmit={handleSave}>  
        <div className={styles.formField}>  
          <Label htmlFor="player_name">Player Name:</Label>  
          <Input  
            id="player_name"  
            value={playerName}  
            onChange={(e) => setPlayerName(e.target.value)}  
            required  
          />  
        </div>  
  
        {/*<div className={styles.formField}>  
          <Label htmlFor="player_position">Preferred Position:</Label>  
          <Combobox  
            placeholder="Select position"  
            id="player_position"  
            value={playerPosition}  
            onOptionSelect={(event, data) =>  
              setPlayerPosition(data.optionValue)  
            }  
            required  
          >  
            <Option value="GS">Goal Shooter</Option>  
            <Option value="GA">Goal Attack</Option>  
            <Option value="WA">Wing Attack</Option>  
            <Option value="C">Centre</Option>  
            <Option value="WD">Wing Defence</Option>  
            <Option value="GD">Goal Defence</Option>  
            <Option value="GK">Goal Keeper</Option>  
          </Combobox>  
        </div>  
  
        <div className={styles.formField}>  
          <Label htmlFor="player_number">Player Number:</Label>  
          <Input  
            id="player_number"  
            value={playerNumber}  
            onChange={(e) => setPlayerNumber(e.target.value)}  
            required  
            type="number"  
          />  
        </div> */} 
  
        <div className={styles.formField}>  
          <Label htmlFor="team_id">Team:</Label>  
          <Combobox  
            placeholder="Select team"  
            id="team_id"  
            value={teamName}  
            onOptionSelect={handleTeamSelection}  
            required  
          >  
            {teams.map((team) => (  
              <Option key={team.id} value={team.id}>  
                {team.name}  
              </Option>  
            ))}  
          </Combobox>  
        </div>  
        <Button type="submit" appearance="primary">  
          Save Player  
        </Button>  
      </form>  
    </div>  
  );  
}  
  
export default AddEditPlayerPage;  
