// frontend/src/components/Navbar.js  
/**  
 * File Name: Navbar.js  
 * Location: src/components  
 * Description: Navigation bar component with theme toggle and logout functionality.  
 */  
  
import React, { useContext } from 'react';  
import { NavLink, useNavigate } from 'react-router-dom';  
import {  
  Toolbar,  
  makeStyles,  
  Button,  
  Switch,  
  Tooltip,  
  shorthands,  
  tokens,  
} from '@fluentui/react-components';  
import { AuthContext } from '../contexts/AuthContext';  
  
const useStyles = makeStyles({  
  toolbarRoot: {  
    backgroundColor: tokens.colorBrandBackground,  
    color: tokens.colorBrandForeground1,  
    ...shorthands.padding('0', '1rem'),  
  },  
  navLink: {  
    color: tokens.colorNeutralForegroundOnBrand,  
    textDecoration: 'none',  
    marginRight: '1rem',  
    ...shorthands.padding('0.5rem'),  
    ...shorthands.borderRadius('4px'),  
    '&.active': {  
      fontWeight: 'bold',  
      backgroundColor: tokens.colorBrandBackgroundPressed,  
    },  
    '&:hover': {  
      backgroundColor: tokens.colorBrandBackgroundHover,  
    },  
  },  
  title: {  
    color: tokens.colorNeutralForegroundOnBrand,  
    fontWeight: 'bold',  
    marginRight: 'auto',  
    fontSize: '1.5rem',  
  },  
  switchContainer: {  
    display: 'flex',  
    alignItems: 'center',  
    marginLeft: '1rem',  
    color: tokens.colorNeutralForegroundOnBrand,  
  },  
  logoutButton: {  
    marginLeft: '1rem',  
    color: tokens.colorNeutralForegroundOnBrand,  
  },  
});  
  
function Navbar({ onThemeChange, currentTheme, user }) {  
  const styles = useStyles();  
  const navigate = useNavigate();  
  const { logout } = useContext(AuthContext);  
  
  const handleLogout = () => {  
    logout();  
    navigate('/');  
  };  
  
  const handleThemeToggle = () => {  
    onThemeChange(currentTheme === 'light' ? 'dark' : 'light');  
  };  
  
  // Adjust navigation items based on user role  
  const navigationItems = [  
    { key: 'dashboard', text: 'Dashboard', path: '/dashboard' },  
    // Show 'Manage Teams', 'Manage Players', and 'Manage Games' only for specific roles  
    ...(user &&  
    (user.role === 'Coach' || user.role === 'Assistant Coach' || user.role === 'Administrator')  
      ? [  
          { key: 'manage_teams', text: 'Manage Teams', path: '/manage_teams' },  
          { key: 'manage_players', text: 'Manage Players', path: '/manage_players' },  
          { key: 'manage_games', text: 'Manage Games', path: '/manage_games' },  
        ]  
      : []),  
    { key: 'track_game', text: 'Track Game', path: '/real_time_tracking' },  
    { key: 'view_statistics', text: 'View Statistics', path: '/view_statistics' },  
    { key: 'settings', text: 'Settings', path: '/settings' },  
  ];  
  
  return (  
    <header>  
      <Toolbar className={styles.toolbarRoot}>  
        <span className={styles.title}>Courtside</span>  
        {navigationItems.map((item) => (  
          <NavLink  
            key={item.key}  
            to={item.path}  
            className={styles.navLink}  
            activeclassname="active"  
          >  
            {item.text}  
          </NavLink>  
        ))}  
  
        <Tooltip  
          content={  
            currentTheme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'  
          }  
        >  
          <Switch  
            checked={currentTheme === 'dark'}  
            onChange={handleThemeToggle}  
            aria-label="Toggle Theme"  
          />  
        </Tooltip>  
  
        <Button  
          onClick={handleLogout}  
          className={styles.logoutButton}  
          appearance="transparent"  
        >  
          Logout  
        </Button>  
      </Toolbar>  
    </header>  
  );  
}  
  
export default Navbar;  
