/**  
 * File Name: AssignPlayerDialog.js  
 * Location: src/components  
 * Description: Dialog component for assigning a player to a position.  
 */  
  
import React from 'react';  
import {  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  Combobox,  
  Option,  
  Button,  
  Label,  
} from '@fluentui/react-components';  
  
const AssignPlayerDialog = ({  
  isOpen,  
  onDismiss,  
  onAssign,  
  position,  
  selectedPlayer,  
  setSelectedPlayer,  
  benchPlayers,  
}) => {  
  return (  
    <Dialog open={isOpen} onOpenChange={(_, data) => !data.open && onDismiss()}>  
      <DialogSurface>  
        <DialogTitle>Assign Player to {position}</DialogTitle>  
        <DialogContent>  
          <Label htmlFor="select_player">Select Player:</Label>  
          <Combobox  
            placeholder="Select a player"  
            id="select_player"  
            value={selectedPlayer}  
            onOptionSelect={(_, data) => setSelectedPlayer(data.optionValue)}  
          >  
            {benchPlayers.map((playerName, index) => (  
              <Option key={index} value={playerName}>  
                {playerName}  
              </Option>  
            ))}  
          </Combobox>  
        </DialogContent>  
        <DialogActions>  
          <Button onClick={onDismiss}>Cancel</Button>  
          <Button onClick={onAssign} appearance="primary">  
            Assign Player  
          </Button>  
        </DialogActions>  
      </DialogSurface>  
    </Dialog>  
  );  
};  
  
export default AssignPlayerDialog;  
