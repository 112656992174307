// src/hooks/useGames.js  
  
import { useState, useEffect } from 'react';  
import GameController from '../controllers/GameController';  
  
export const useGames = (teamId) => {  
  const [games, setGames] = useState([]);  
  const [loading, setLoading] = useState(false); // Loading state  
  const [error, setError] = useState(null);      // Error state  
  
  useEffect(() => {  
    if (!teamId) {  
      setGames([]);  
      return;  
    }  
  
    const fetchGamesForTeam = async (teamId) => {  
      try {  
        setLoading(true);  
        const gamesData = await GameController.getGamesByTeamId(teamId);  
        setGames(gamesData);  
      } catch (error) {  
        console.error('Error fetching games for team:', error);  
        setError(error);  
      } finally {  
        setLoading(false);  
      }  
    };  
  
    fetchGamesForTeam(teamId);  
  }, [teamId]);  
  
  return { games, loading, error };  
};  
