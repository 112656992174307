// frontend/src/pages/LoginPage.js  
/**      
 * File Name: LoginPage.js      
 * Location: frontend/src/pages      
 * Description: Login page with improved affordances, signifiers, and feedback.      
 */  
  
import React, { useState, useContext } from 'react';  
import { useNavigate } from 'react-router-dom';  
import { mergeClasses, Card, Input, Field, Checkbox, Button, Link, Title3, makeStyles, MessageBar, CardHeader, CardFooter } from '@fluentui/react-components';  
import { AuthContext } from '../contexts/AuthContext';  
import api from '../api';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: `linear-gradient(135deg, blue 0%, white 100%)`,
  },
  card: {
    width: '100%',
    maxWidth: '400px',
    padding: '2rem',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  input: {
    marginBottom: '1rem',
  },
  button: {
    alignSelf: 'center',
    padding: '0.5rem 2rem',
  },
  footer: {
    textAlign: 'center',
    paddingTop: '1rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '1rem',
  },
  messageBar: {
    marginBottom: '1rem',
  },
  logo: {
    height: '48px',
    width: '48px',
    borderRadius: '8px',
  },
  pointer: {
    cursor: 'pointer',
  },
});

const LoginPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (event) => {  
    event.preventDefault();  
    if (!username || !password) {  
      setErrorMessage('Please enter both username and password.');  
      return;  
    }  
  
    try {  
      const response = await api.post('/auth/login', { username, password });  
      const { accessToken, refreshToken, user } = response.data;  
      login(user, accessToken, refreshToken);  
      navigate('/dashboard');  
    } catch (error) {  
      console.error('Login error:', error);  
      setErrorMessage(error.response?.data?.message || 'Invalid username or password.');  
    }  
  };

  const combinedClasses = mergeClasses(classes.logo, classes.pointer);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardHeader 
          className={classes.header}
          header={<Title3>Courtside</Title3>}
        />
        {errorMessage && (
          <MessageBar className={classes.messageBar} intent="error">
            {errorMessage}
          </MessageBar>
        )}
        <form className={classes.form} onSubmit={handleLogin}>
          <Field label="Username">
            <Input
              className={classes.input}
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Field>
          {username && (
            <Field label="Password">
              <Input
                className={classes.input}
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Field>
          )}
          {/*<Checkbox 
            label="Remember me" 
            checked={rememberMe} 
            onChange={() => setRememberMe(!rememberMe)} 
          />*/}
          {/*<Link href="/app/forgot-password">Forgot Password?</Link>*/}
          <Button className={classes.button} type="submit" appearance="primary" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>
        <CardFooter className={classes.footer}>
          <p>
            {/*Don't have an account? <a href="/app/loganAI-register">Register here</a>*/}
          </p>
        </CardFooter>
      </Card>
    </div>
  );
};

export default LoginPage;