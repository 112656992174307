// frontend/src/pages/ScheduleGamePage.js  
/**  
 * File Name: ScheduleGamePage.js  
 * Location: frontend/src/pages  
 * Description: Component for scheduling or editing game details with support for selecting game type (6-aside or 7-aside) and entering custom opponent team names.  
 */  
  
import React, { useState, useEffect } from 'react';  
import { Button, Input, Label, Combobox, Option, makeStyles } from '@fluentui/react-components';  
import GameController from '../controllers/GameController';  
import TeamController from '../controllers/TeamController';  
import { useNavigate, useLocation } from 'react-router-dom';  
import { v4 as uuidv4 } from 'uuid';  
  
const useStyles = makeStyles({  
  content: {  
    padding: '20px',  
    maxWidth: '600px',  
    margin: '0 auto',  
  },  
  formField: {  
    margin: '10px 0',  
  },  
});  
  
function ScheduleGamePage() {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const [gameId, setGameId] = useState(null);  
  const [gameDate, setGameDate] = useState('');  
  const [homeTeamId, setHomeTeamId] = useState('');  
  const [awayTeamId, setAwayTeamId] = useState('');  
  const [awayTeamName, setAwayTeamName] = useState(''); // New state for custom opponent team name  
  const [locationField, setLocationField] = useState('');  
  const [gameType, setGameType] = useState('7-aside');  
  const [teams, setTeams] = useState([]);  
  const styles = useStyles();  
  
  useEffect(() => {  
    fetchTeams();  
    if (location.state && location.state.id) {  
      fetchGame(location.state.id);  
    }  
  }, [location.state]);  
  
  const fetchGame = async (id) => {  
    try {  
      const game = await GameController.getGameById(id);  
      if (game) {  
        setGameId(game.id);  
        setGameDate(game.date);  
        setHomeTeamId(game.homeTeamId);  
        setAwayTeamId(game.awayTeamId !== 'custom' ? game.awayTeamId : '');  
        setAwayTeamName(game.awayTeamId === 'custom' ? game.awayTeamName : '');  
        setLocationField(game.location);  
        setGameType(game.gameType || '7-aside');  
      }  
    } catch (error) {  
      console.error('Error fetching game:', error);  
    }  
  };  
  
  const fetchTeams = async () => {  
    try {  
      const teamsData = await TeamController.getAllTeams();  
      setTeams(teamsData);  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
    }  
  };  
  
  const handleAwayTeamChange = (event, data) => {  
    if (data.optionValue === 'custom') {  
      setAwayTeamId('custom');  
      setAwayTeamName('');  
    } else {  
      setAwayTeamId(data.optionValue);  
      setAwayTeamName('');  
    }  
  };  
  
  const handleSave = async (event) => {  
    event.preventDefault();  
    const gameData = {  
      date: gameDate,  
      homeTeamId: homeTeamId,  
      awayTeamId: awayTeamId || 'custom', // Use 'custom' identifier if a custom team name is provided  
      awayTeamName: awayTeamId === 'custom' ? awayTeamName : null, // Set custom team name  
      location: locationField,  
      gameType: gameType,  
    };  
  
    try {  
      if (gameId) {  
        gameData.id = gameId;  
        await GameController.updateGame(gameData);  
      } else {  
        gameData.id = uuidv4();  
        await GameController.addGame(gameData);  
      }  
      navigate('/manage_games');  
    } catch (error) {  
      console.error('Error saving game:', error);  
    }  
  };  
  
  return (  
    <div className={styles.content}>  
      <h2>{gameId ? 'Edit Game' : 'Schedule New Game'}</h2>  
      <form onSubmit={handleSave}>  
        <div className={styles.formField}>  
          <Label htmlFor="game_date">Game Date:</Label>  
          <Input  
            type="date"  
            id="game_date"  
            value={gameDate}  
            onChange={(e) => setGameDate(e.target.value)}  
            required  
          />  
        </div>  
        <div className={styles.formField}>  
          <Label htmlFor="home_team">Home Team:</Label>  
          <Combobox  
            placeholder="Select Home Team"  
            id="home_team"  
            value={teams.find((team) => team.id === homeTeamId)?.name || ''}  
            onOptionSelect={(e, data) => setHomeTeamId(data.optionValue)}  
            required  
          >  
            {teams.map((team) => (  
              <Option key={team.id} value={team.id}>  
                {team.name}  
              </Option>  
            ))}  
          </Combobox>  
        </div>  
        <div className={styles.formField}>  
          <Label htmlFor="away_team">Away Team:</Label>  
          <Combobox  
            placeholder="Select Away Team or Enter Custom Name"  
            id="away_team"  
            value={  
              awayTeamId  
                ? teams.find((team) => team.id === awayTeamId)?.name || ''  
                : awayTeamName  
            }  
            onOptionSelect={handleAwayTeamChange}  
            required  
          >  
            {teams.map((team) => (  
              <Option key={team.id} value={team.id}>  
                {team.name}  
              </Option>  
            ))}  
            <Option value="custom">Enter Custom Name...</Option>  
          </Combobox>  
          {awayTeamId === 'custom' && (  
            <Input  
              placeholder="Enter Opponent Team Name"  
              value={awayTeamName}  
              onChange={(e) => setAwayTeamName(e.target.value)}  
              required  
            />  
          )}  
        </div>  
        <div className={styles.formField}>  
          <Label htmlFor="location">Location:</Label>  
          <Input  
            id="location"  
            value={locationField}  
            onChange={(e) => setLocationField(e.target.value)}  
          />  
        </div>  
        <div className={styles.formField}>  
          <Label htmlFor="game_type">Game Type:</Label>  
          <Combobox  
            id="game_type"  
            placeholder="Select Game Type"  
            value={gameType}  
            onOptionSelect={(e, data) => setGameType(data.optionValue)}  
            required  
          >  
            <Option value="7-aside">7-aside</Option>  
            <Option value="6-aside">6-aside</Option>  
          </Combobox>  
        </div>  
        <Button type="submit" appearance="primary">  
          Save Game  
        </Button>  
      </form>  
    </div>  
  );  
}  
  
export default ScheduleGamePage;  
