// src/components/GoalsPerGameChart.js  
  
import React from 'react';  
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';  
import PropTypes from 'prop-types';  
  
const GoalsPerGameChart = ({ data, gameType }) => (  
  <div>  
    <h3>Goals Per Game ({gameType})</h3>  
    <BarChart width={600} height={300} data={data}>  
      <CartesianGrid stroke="#f5f5f5" />  
      <XAxis dataKey="game" />  
      <YAxis allowDecimals={false} />  
      <Tooltip />  
      <Legend />  
      <Bar dataKey="teamPoints" name="Team Points" fill="#8884d8" />  
      <Bar dataKey="opponentPoints" name="Opponent Points" fill="#82ca9d" />  
    </BarChart>  
  </div>  
);  
  
GoalsPerGameChart.propTypes = {  
  data: PropTypes.array.isRequired,  
  gameType: PropTypes.string.isRequired,  
};  
  
export default GoalsPerGameChart;  
