// src/hooks/useTeams.js  
  
import { useState, useEffect } from 'react';  
import TeamController from '../controllers/TeamController';  
  
export const useTeams = () => {  
  const [teams, setTeams] = useState([]);  
  const [loading, setLoading] = useState(true); // Loading state  
  const [error, setError] = useState(null);     // Error state  
  
  useEffect(() => {  
    const fetchTeams = async () => {  
      try {  
        const teamsData = await TeamController.getAllTeams();  
        setTeams(teamsData);  
      } catch (error) {  
        console.error('Error fetching teams:', error);  
        setError(error);  
      } finally {  
        setLoading(false);  
      }  
    };  
  
    fetchTeams();  
  }, []);  
  
  return { teams, loading, error };  
};  
