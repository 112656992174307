// src/pages/ViewStatisticsPage.js  
  
import React, { useState, useEffect } from 'react';  
import { useTeams } from '../hooks/useTeams';  
import { usePlayers } from '../hooks/usePlayers';  
import { useGames } from '../hooks/useGames';  
import { useEvents } from '../hooks/useEvents';  
import usePositions from '../hooks/usePositions';  
  
import SelectionControls from '../components/SelectionControls';  
import TeamStatisticsTable from '../components/TeamStatisticsTable';  
import PlayerStatisticsTable from '../components/PlayerStatisticsTable';  
import PositionsBreakdownTable from '../components/PositionsBreakdownTable';  
import QuarterlyDataTable from '../components/QuarterlyDataTable';  
import TeamPerformanceChart from '../components/TeamPerformanceChart';  
import GoalsPerGameChart from '../components/GoalsPerGameChart';  
import Loading from '../components/Loading';  
import Error from '../components/Error';  
  
import {  
  calculateOverallTeamStatistics,  
  calculateTeamStatisticsByGameType,  
  calculatePlayerStatsByGameType,  
  calculateTeamPerformance,  
  calculateGoalsPerGame,  
} from '../utils/statistics';  
  
const ViewStatisticsPage = () => {  
  // State variables  
  const [selectedTeamId, setSelectedTeamId] = useState('');  
  const [selectedGameId, setSelectedGameId] = useState('');  
  const [selectedPlayerId, setSelectedPlayerId] = useState('');  
  const [selectedPosition, setSelectedPosition] = useState('');  
  const [playerStatistics, setPlayerStatistics] = useState(null);  
  const [playerTotals, setPlayerTotals] = useState(null);  
  
  // Custom hooks for fetching data  
  const { teams, loading: teamsLoading, error: teamsError } = useTeams();  
  const { players, loading: playersLoading, error: playersError } = usePlayers();  
  const { games, loading: gamesLoading, error: gamesError } = useGames(selectedTeamId);  
  const { events, loading: eventsLoading, error: eventsError } = useEvents(  
    selectedTeamId,  
    selectedGameId,  
    players  
  );  
  
  // Computed statistics  
  const [overallTeamStats, setOverallTeamStats] = useState({});  
  const [teamStatisticsByGameType, setTeamStatisticsByGameType] = useState({});  
  const [playerStatsByGameType, setPlayerStatsByGameType] = useState({});  
  const [teamPerformanceData, setTeamPerformanceData] = useState({});  
  const [goalsPerGameData, setGoalsPerGameData] = useState({});  
  
  // Effects  
  useEffect(() => {  
    if (events.length > 0) {  
      // Filter events for the selected game if applicable  
      const filteredEvents = selectedGameId  
        ? events.filter((event) => event.gameId === selectedGameId)  
        : events;  
  
      const overallStats = calculateOverallTeamStatistics(filteredEvents);  
      setOverallTeamStats(overallStats);  
  
      // Calculate team statistics by game type  
      const statsByGameType = calculateTeamStatisticsByGameType(filteredEvents);  
      setTeamStatisticsByGameType(statsByGameType);  
  
      // Calculate player statistics by game type  
      const playerStatsByGameTypeData = calculatePlayerStatsByGameType(filteredEvents);  
      setPlayerStatsByGameType(playerStatsByGameTypeData);  
  
      // Calculate team performance data  
      const performanceData = calculateTeamPerformance(filteredEvents);  
      setTeamPerformanceData(performanceData);  
  
      // Calculate goals per game data  
      const goalsData = calculateGoalsPerGame(filteredEvents, games, getOpponentName, selectedTeamId);  
      setGoalsPerGameData(goalsData);  
  
    } else {  
      // Clear statistics if no events  
      setOverallTeamStats({});  
      setTeamStatisticsByGameType({});  
      setPlayerStatistics(null);  
      setPlayerTotals(null);  
      setPlayerStatsByGameType({});  
      setTeamPerformanceData({});  
      setGoalsPerGameData({});  
    }  
  }, [events, selectedGameId, selectedPlayerId, games]);  
  
  // Function to get opponent name  
  const getOpponentName = (game) => {  
    // Implement this function as per your code  
  };  
  
  // Loading and error states  
  const loading = teamsLoading || playersLoading || gamesLoading || eventsLoading;  
  const error = teamsError || playersError || gamesError || eventsError;  
  
  return (  
    <div>  
      <h2>View Statistics</h2>  
  
      {/* Loading and Error States */}  
      {loading && <Loading />}  
      {error && <Error message={error.message} />}  
  
      {/* Selection Controls */}  
      {!loading && !error && (  
        <SelectionControls  
          teams={teams}  
          games={games}  
          players={players}  
          positions={[]}  
          selectedTeamId={selectedTeamId}  
          onTeamSelect={setSelectedTeamId}  
          selectedGameId={selectedGameId}  
          onGameSelect={setSelectedGameId}  
          selectedPlayerId={selectedPlayerId}  
          onPlayerSelect={setSelectedPlayerId}  
          selectedPosition={selectedPosition}  
          onPositionSelect={setSelectedPosition}  
          getOpponentName={getOpponentName}  
        />  
      )}  
  
      {/* Team Statistics */}  
      {(Object.keys(overallTeamStats).length > 0 || selectedGameId) && (  
        <>  
          <h3>Team Statistics</h3>  
          {selectedGameId ? (  
            teamStatisticsByGameType.All ? (  
              <TeamStatisticsTable stats={teamStatisticsByGameType.All} />  
            ) : (  
              <p>No statistics available.</p>  
            )  
          ) : (  
            ['6-aside', '7-aside'].map((gameType) =>  
              overallTeamStats[gameType] ? (  
                <div key={gameType}>  
                  <h4>{gameType}</h4>  
                  <TeamStatisticsTable stats={overallTeamStats[gameType]} />  
                </div>  
              ) : null  
            )  
          )}  
        </>  
      )}  
  
      {/* Player Statistics */}  
      {selectedPlayerId ? (  
        // Modify as needed similar to team statistics  
        playerStatsByGameType && (  
          <>  
            <h3>Player Statistics</h3>  
            {selectedGameId ? (  
              playerStatsByGameType.All && (  
                <PlayerStatisticsTable playerStats={playerStatsByGameType.All} />  
              )  
            ) : (  
              ['6-aside', '7-aside'].map((gameType) =>  
                playerStatsByGameType[gameType] ? (  
                  <div key={gameType}>  
                    <h4>{gameType}</h4>  
                    <PlayerStatisticsTable playerStats={playerStatsByGameType[gameType]} />  
                  </div>  
                ) : null  
              )  
            )}  
          </>  
        )  
      ) : null}  
  
      {/* Charts */}  
      {teamPerformanceData && (  
      Object.keys(teamPerformanceData).map((gameType) => (  
        <div key={gameType}>  
          <h4>{gameType} Team Performance</h4>  
          {teamPerformanceData[gameType].length > 0 ? (  
            <TeamPerformanceChart data={teamPerformanceData[gameType]} gameType={gameType} />  
          ) : (  
            <p>No valid data available for {gameType}.</p>  
          )}  
        </div>  
      ))  
    )}   
  
      {goalsPerGameData && (  
        Object.keys(goalsPerGameData).map((gameType) => (  
          <div key={gameType}>  
            <h4>{gameType} Goals Per Game</h4>  
            <GoalsPerGameChart data={goalsPerGameData[gameType]} gameType={gameType} />  
          </div>  
        ))  
      )}  
  
    </div>  
  );  
};  
  
export default ViewStatisticsPage;  
