/**  
 * File Name: PositionActionDialog.js  
 * Location: src/components  
 * Description: Dialog component for actions related to a position.  
 */  
  
import React from 'react';  
import {  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  Button,  
} from '@fluentui/react-components';  
  
const PositionActionDialog = ({  
  isOpen,  
  onDismiss,  
  position,  
  onRemovePlayer,  
  onAssignPlayer,  
}) => {  
  return (  
    <Dialog open={isOpen} onOpenChange={(_, data) => !data.open && onDismiss()}>  
      <DialogSurface>  
        <DialogTitle>Position {position}</DialogTitle>  
        <DialogContent>  
          <p>A player is already assigned to this position.</p>  
          <p>What would you like to do?</p>  
        </DialogContent>  
        <DialogActions>  
          <Button onClick={onDismiss}>Cancel</Button>  
          <Button onClick={onRemovePlayer}>Remove Player</Button>  
          <Button onClick={onAssignPlayer} appearance="primary">  
            Assign Player  
          </Button>  
        </DialogActions>  
      </DialogSurface>  
    </Dialog>  
  );  
};  
  
export default PositionActionDialog;  
