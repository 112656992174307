// src/components/TeamStatisticsTable.js  
  
import React from 'react';  
import PropTypes from 'prop-types';  
  
const TeamStatisticsTable = ({ stats }) => (  
  <table>  
    <tbody>  
      <tr>  
        <td>Total Games Played</td>  
        <td>{stats.totalGames}</td>  
      </tr>  
      <tr>  
        <td>Total Points For</td>  
        <td>{stats.totalPointsFor}</td>  
      </tr>  
      <tr>  
        <td>Total Points Against</td>  
        <td>{stats.totalPointsAgainst}</td>  
      </tr>  
      <tr>  
        <td>Point Difference</td>  
        <td>{stats.pointDifference}</td>  
      </tr>  
      <tr>  
        <td>Point Percentage</td>  
        <td>{stats.pointPercentage}%</td>  
      </tr>  
      <tr>  
        <td>Total Shots Attempted</td>  
        <td>{stats.totalShots}</td>  
      </tr>  
      <tr>  
        <td>Shooting Percentage</td>  
        <td>{stats.shootingPercentage}%</td>  
      </tr>  
      <tr>  
        <td>Total Turnovers For</td>  
        <td>{stats.turnoversFor}</td>  
      </tr>  
      <tr>  
        <td>Total Turnovers Against</td>  
        <td>{stats.turnoversAgainst}</td>  
      </tr>  
    </tbody>  
  </table>  
);  
  
TeamStatisticsTable.propTypes = {  
  stats: PropTypes.object.isRequired,  
};  
  
export default TeamStatisticsTable;  
