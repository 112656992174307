// frontend/src/pages/RealTimeTrackingPage.js  
/**  
 * File Name: RealTimeTrackingPage.js  
 * Location: src/pages  
 * Description: Main page component for real-time game tracking. Ensures scoreboard is correctly updated after data fetch by passing 'game' to useEventLog and handling data dependencies.  
 */  
  
import React, { useState, useEffect } from 'react';  
import {  
  Button,  
  Combobox,  
  Option,  
  makeStyles,  
  Label,  
  tokens,  
} from '@fluentui/react-components';  
  
import { getQuarterDuration, getTeamName } from '../utils/utils';  
  
import useGameData from '../hooks/useGameData';  
import usePlayersData from '../hooks/usePlayersData';  
import useEventLog from '../hooks/useEventLog';  
import useGameClock from '../hooks/useGameClock';  
import usePositions from '../hooks/usePositions';  
  
import NetballCourt7Aside from '../components/NetballCourt7Aside';  
import NetballCourt6Aside from '../components/NetballCourt6Aside';  
import AssignPlayerDialog from '../components/AssignPlayerDialog';  
import PositionActionDialog from '../components/PositionActionDialog';  
import EventLogModal from '../components/EventLogModal';  
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';  
import ActionButtons from '../components/ActionButtons';  
import Scoreboard from '../components/Scoreboard';  
import GameClock from '../components/GameClock';  
import Bench from '../components/Bench';  
  
import { v4 as uuidv4 } from 'uuid'; // Import uuid for generating unique IDs  
import EventController from '../controllers/EventController'; // Import EventController to save events  
  
const useStyles = makeStyles({  
  content: {  
    padding: '10px',  
    boxSizing: 'border-box',  
    display: 'flex',  
    flexDirection: 'column',  
    backgroundColor: tokens.colorNeutralBackground1,  
    height: '100vh',  
    overflow: 'hidden',  
  },  
  mainContainer: {  
    display: 'flex',  
    flexGrow: 1,  
    minHeight: 0,  
    flexWrap: 'wrap',  
    overflow: 'auto',  
    '@media (max-width: 768px)': {  
      flexDirection: 'column',  
    },  
  },  
  courtContainer: {  
    flex: '1 1 60%',  
    minWidth: '300px',  
    display: 'flex',  
    flexDirection: 'column',  
    overflowY: 'auto',  
    '@media (max-width: 768px)': {  
      flex: '1 1 100%',  
    },  
  },  
  actionsContainer: {  
    flex: '1 1 40%',  
    minWidth: '280px',  
    display: 'flex',  
    flexDirection: 'column',  
    gap: '10px',  
    overflowY: 'auto',  
    '@media (max-width: 768px)': {  
      flex: '1 1 100%',  
    },  
    backgroundColor: tokens.colorNeutralBackground1,  
  },  
  actionButton: {  
    minHeight: '50px',  
    flexGrow: 1,  
    fontSize: '14px',  
    textAlign: 'center',  
    color: tokens.colorNeutralForeground1Static,  
    borderRadius: '8px',  
    flex: '1 1 auto',  
    '&:hover': {  
      opacity: 0.9,  
    },  
  },  
  // Assign different colors to buttons  
  rowA1Button: {  
    backgroundColor: '#FFB6C1', // Light Pink for A1 row  
  },  
  rowA2Button: {  
    backgroundColor: '#ADD8E6', // Light Blue for A2 row  
  },  
  rowC1Button: {  
    backgroundColor: '#90EE90', // Light Green for C1 row  
  },  
  rowC2Button: {  
    backgroundColor: '#FFDAB9', // Light Peach for C2 row  
  },  
  rowGAButton: {  
    backgroundColor: '#FFB6C1', // Light Pink for GA row  
  },  
  rowGSButton: {  
    backgroundColor: '#ADD8E6', // Light Blue for GS row  
  },  
  utilButton: {  
    backgroundColor: '#D3D3D3', // Light Gray for utility buttons  
  },  
  // Specific function colors within rows  
  goalButton: {  
    border: '2px solid #006400', // Dark Green border for Goal buttons  
  },  
  missedShotButton: {  
    border: '2px solid #8B0000', // Dark Red border for Missed Shot buttons  
  },  
  benchContainer: {  
    marginTop: '20px',  
  },  
  benchTitle: {  
    marginTop: '20px',  
  },  
  benchList: {  
    listStyle: 'none',  
    padding: '0',  
  },  
  benchPlayer: {  
    padding: '5px 0',  
  },  
  buttonGroup: {  
    marginTop: '20px',  
    display: 'flex',  
    flexWrap: 'wrap',  
    gap: '10px',  
  },  
  eventLog: {  
    maxHeight: '300px',  
    overflowY: 'auto',  
    listStyleType: 'none',  
    padding: 0,  
  },  
  scoreboard: {  
    marginTop: '20px',  
    overflowY: 'auto',  
    backgroundColor: tokens.colorNeutralBackground2,  
    border: `2px solid ${tokens.colorNeutralStroke1}`,  
    borderRadius: tokens.borderRadiusMedium,  
    padding: '10px',  
    boxShadow: tokens.shadow4,  
  },  
  buttonRow: {  
    display: 'flex',  
    flexWrap: 'wrap',  
    gap: '10px',  
    marginBottom: '10px',  
  },  
  combobox: {  
    marginBottom: '10px',  
  },  
  header: {  
    marginBottom: '10px',  
    '@media (max-width: 480px)': {  
      fontSize: '18px',  
    },  
  },  
  clockContainer: {  
    marginBottom: '10px',  
  },  
});  
  
const RealTimeTrackingPage = () => {  
  const styles = useStyles();  
  
  const [myTeamId, setMyTeamId] = useState(null); 

  useEffect(() => {  
    console.log('My Team ID:', myTeamId);  
  }, [myTeamId]); 
  
  // Custom Hooks  
  const {  
    gameId,  
    setGameId,  
    game, // Ensure game data is fetched  
    games,  
    teams,  
    homeTeam,  
    awayTeam,  
    gameType,  
  } = useGameData(myTeamId);  
  
  const [currentQuarter, setCurrentQuarter] = useState(1);  
  
  const {  
    benchPlayers,  
    assignedPlayers,  
    assignedPlayerIds,  
    playerNameToIdMap,  
    assignPlayer,  
    unassignPlayer,  
  } = usePlayersData();  
  
  // Initialize positions based on gameType  
  const { positions, positionOptions } = usePositions(gameType);  
  
  // Use useEventLog hook, passing 'game' as a parameter  
  const {  
    eventLog,  
    playerStats,  
    homeTeamScores,  
    awayTeamScores,  
    recordEvent,  
    deleteEvent,  
    fetchEvents,  
  } = useEventLog(  
    gameId,  
    gameType,  
    myTeamId,  
    assignedPlayers,  
    assignedPlayerIds,  
    positions,  
    currentQuarter,  
    teams,  
    game, // Pass game here  
    setCurrentQuarter  
  );  
  
  // Initialize the clock after ensuring gameType is available  
  const initialTime = getQuarterDuration(gameType);  
  
  const {  
    clockTime,  
    isClockRunning,  
    startClock,  
    pauseClock,  
    resetClock,  
    addTime,  
  } = useGameClock(initialTime, () => endQuarter());  
  
  // Dialog States  
  const [isAssignDialogOpen, setIsAssignDialogOpen] = useState(false);  
  const [selectedPosition, setSelectedPosition] = useState('');  
  const [selectedPlayer, setSelectedPlayer] = useState('');  
  const [isPositionActionDialogOpen, setIsPositionActionDialogOpen] = useState(false);  
  const [isEventLogOpen, setIsEventLogOpen] = useState(false);  
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);  
  const [eventToDelete, setEventToDelete] = useState(null);  
  
  // Handle Functions  
  const handlePlayerClick = (position) => {  
    if (assignedPlayers[position]) {  
      setSelectedPosition(position);  
      setIsPositionActionDialogOpen(true);  
    } else {  
      setSelectedPosition(position);  
      setSelectedPlayer('');  
      setIsAssignDialogOpen(true);  
    }  
  };  
  
  const savePlayerAssignment = async () => {  
    if (selectedPlayer) {  
      const playerId = playerNameToIdMap[selectedPlayer];  
      assignPlayer(selectedPosition, selectedPlayer, playerId);  
  
      // Construct the PositionAssignment event  
      const newEvent = {  
        id: uuidv4(),  
        eventType: 'PositionAssignment',  
        quarter: currentQuarter,  
        timestamp: new Date().toISOString(),  
        position: selectedPosition,  
        playerName: selectedPlayer,  
        playerId: playerId,  
        points: null,  
        gameType,  
        gameId: gameId,  
        teamId: myTeamId,  
      };  
  
      // Save event to backend  
      try {  
        await EventController.saveEvent(newEvent);  
  
        // Fetch updated events to rebuild state  
        await fetchEvents(gameId);  
      } catch (error) {  
        console.error('Error saving position assignment event:', error);  
      }  
  
      setIsAssignDialogOpen(false);  
    }  
  };  
  
  const onRemovePlayer = async () => {  
    const playerName = assignedPlayers[selectedPosition];  
    const playerId = assignedPlayerIds[selectedPosition];  
    if (playerId) {  
      unassignPlayer(selectedPosition);  
  
      // Construct the PositionUnassignment event  
      const newEvent = {  
        id: uuidv4(),  
        eventType: 'PositionUnassignment',  
        quarter: currentQuarter,  
        timestamp: new Date().toISOString(),  
        position: selectedPosition,  
        playerName: playerName,  
        playerId: playerId,  
        points: null,  
        gameType,  
        gameId: gameId,  
        teamId: myTeamId,  
      };  
  
      // Save event to backend  
      try {  
        await EventController.saveEvent(newEvent);  
  
        // Fetch updated events to rebuild state  
        await fetchEvents(gameId);  
      } catch (error) {  
        console.error('Error saving position unassignment event:', error);  
      }  
    }  
    setIsPositionActionDialogOpen(false);  
  };  
  
  const onAssignPlayer = () => {  
    setIsPositionActionDialogOpen(false);  
    setSelectedPlayer('');  
    setIsAssignDialogOpen(true);  
  };  
  
  const endQuarter = () => {  
    pauseClock();  
    if (currentQuarter < 4) {  
      setCurrentQuarter((prev) => prev + 1);  
      alert(`Quarter ${currentQuarter} ended. Starting Quarter ${currentQuarter + 1}.`);  
      resetClock(getQuarterDuration(gameType));  
      startClock(); // Automatically start the next quarter  
    } else {  
      endGame();  
    }  
  };  
  
  const endGame = () => {  
    pauseClock();  
    alert('Game ended.');  
    // Perform any cleanup or finalization here  
  };  
  
  const handleDeleteEvent = (event) => {  
    setEventToDelete(event);  
    setIsDeleteConfirmOpen(true);  
  };  
  
  const confirmDeleteEvent = () => {  
    deleteEvent(eventToDelete.id);  
    setIsDeleteConfirmOpen(false);  
  };  
  
  return (  
    <div className={styles.content}>  
    <h2 className={styles.header}>Game Tracking</h2>  
  
    {/* Game Selection */}  
    <Label htmlFor="select_game">Select a Game:</Label>  
    <Combobox  
      className={styles.combobox}  
      placeholder="Select a Game"  
      id="select_game"  
      value={  
        game  
          ? `${game.date} - ${getTeamName(game.homeTeamId, teams)} vs. ${getTeamName(  
              game.awayTeamId,  
              teams,  
              game.awayTeamName  
            )}`  
          : ''  
      }  
      onOptionSelect={(_, data) => setGameId(data.optionValue)}  
    >  
      {games.map((game) => (  
        <Option key={game.id} value={game.id}>  
          {game.date} - {getTeamName(game.homeTeamId, teams)} vs.{' '}  
          {getTeamName(game.awayTeamId, teams, game.awayTeamName)}  
        </Option>  
      ))}  
    </Combobox>  
  
    {game && homeTeam && awayTeam && (  
      <h3>  
        Game: {homeTeam.name} vs. {awayTeam.name} on {game.date} ({game.gameType || '7-aside'})  
      </h3>  
    )}  
  
    {/* Team Selection */}  
    {game && teams.length > 0 && (  
      <>  
        <Label htmlFor="select_my_team">Select Your Team:</Label>  
        <Combobox  
          className={styles.combobox}  
          id="select_my_team"  
          placeholder="Select Your Team"  
          value={  
            myTeamId  
              ? teams.find((team) => team.id === myTeamId)?.name || ''  
              : ''  
          }  
          onOptionSelect={(_, data) => setMyTeamId(data.optionValue)}  
        >  
          {teams.map((team) => (  
            <Option key={team.id} value={team.id}>  
              {team.name}  
            </Option>  
          ))}  
        </Combobox>  
      </>  
    )}  
  
    {/* Conditionally Render the Main Content */}
    {myTeamId ? (  
      <>  
      {/* Main Container */}  
      <div className={styles.mainContainer}>  
        {/* Court Container */}  
        <div className={styles.courtContainer}>  
          {gameType === '7-aside' ? (  
            <NetballCourt7Aside  
              assignedPlayers={assignedPlayers}  
              playerStats={playerStats}  
              handlePlayerClick={handlePlayerClick}  
            />  
          ) : (  
            <NetballCourt6Aside  
              assignedPlayers={assignedPlayers}  
              playerStats={playerStats}  
              handlePlayerClick={handlePlayerClick}  
            />  
          )}  
        </div>  
  
        {/* Actions Container */}  
        <div className={styles.actionsContainer}>  
          <ActionButtons  
            gameType={gameType}  
            positions={positions}  
            assignedPlayers={assignedPlayers}  
            recordEvent={recordEvent}  
            styles={styles}  
            positionOptions={positionOptions}  
            setIsEventLogOpen={setIsEventLogOpen}  
          />  
  
          <Scoreboard  
            homeTeamName={homeTeam ? homeTeam.name : 'Home Team'}  
            awayTeamName={awayTeam ? awayTeam.name : game?.awayTeamName || 'Away Team'}  
            homeTeamScores={homeTeamScores}  
            awayTeamScores={awayTeamScores}  
          />  
  
          <div className={styles.clockContainer}>  
            <GameClock  
              time={clockTime}  
              isRunning={isClockRunning}  
              onStart={startClock}  
              onPause={pauseClock}  
              onReset={() => resetClock(getQuarterDuration(gameType))}  
              onAddTime={addTime}  
            />  
          </div>  
  
          <Bench benchPlayers={benchPlayers} />  
  
          {/* Actions */}  
          <div className={styles.buttonGroup}>  
            <Button onClick={endQuarter}>End Quarter</Button>  
            <Button onClick={endGame} appearance="primary">  
              End Game  
            </Button>  
          </div>  
        </div>  
      </div>  
  
      {/* Dialogs */}  
      <AssignPlayerDialog  
        isOpen={isAssignDialogOpen}  
        onDismiss={() => setIsAssignDialogOpen(false)}  
        onAssign={savePlayerAssignment}  
        position={selectedPosition}  
        selectedPlayer={selectedPlayer}  
        setSelectedPlayer={setSelectedPlayer}  
        benchPlayers={benchPlayers}  
      />  
  
      <PositionActionDialog  
        isOpen={isPositionActionDialogOpen}  
        onDismiss={() => setIsPositionActionDialogOpen(false)}  
        position={selectedPosition}  
        onRemovePlayer={onRemovePlayer}  
        onAssignPlayer={onAssignPlayer}  
      />  
  
      <EventLogModal  
        isOpen={isEventLogOpen}  
        onDismiss={() => setIsEventLogOpen(false)}  
        eventLog={eventLog}  
        onDeleteEvent={handleDeleteEvent}  
      />  
  
      <DeleteConfirmDialog  
        isOpen={isDeleteConfirmOpen}  
        onDismiss={() => setIsDeleteConfirmOpen(false)}  
        onConfirm={confirmDeleteEvent}  
      /> 
       </>
    ) : (  
      <div>  
        <p>Please select your team to start tracking.</p>  
      </div>  
    )}  
  </div>  
); 
}   
  
export default RealTimeTrackingPage;  
