/**  
 * File Name: DeleteConfirmDialog.js  
 * Location: src/components  
 * Description: Dialog component to confirm deletion of an event.  
 */  
  
import React from 'react';  
import {  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  Button,  
} from '@fluentui/react-components';  
import { tokens } from '@fluentui/react-components';  
  
const DeleteConfirmDialog = ({ isOpen, onDismiss, onConfirm }) => {  
  return (  
    <Dialog open={isOpen} onOpenChange={(_, data) => !data.open && onDismiss()}>  
      <DialogSurface>  
        <DialogTitle>Delete Event</DialogTitle>  
        <DialogContent>Are you sure you want to delete this event?</DialogContent>  
        <DialogActions>  
          <Button onClick={onDismiss}>Cancel</Button>  
          <Button  
            onClick={onConfirm}  
            appearance="primary"  
            style={{ backgroundColor: tokens.colorPaletteRedBackground3 }}  
          >  
            Delete  
          </Button>  
        </DialogActions>  
      </DialogSurface>  
    </Dialog>  
  );  
};  
  
export default DeleteConfirmDialog;  
