// frontend/src/components/SelectionControls.js  
/**  
 * File Name: SelectionControls.js  
 * Location: src/components  
 * Description: Component to include position filtering capabilities using Fluent UI React v9's Combobox component.  
 */  
  
import React from 'react';  
import PropTypes from 'prop-types';  
import { Combobox, Option, Label, makeStyles, shorthands } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  container: {  
    display: 'flex',  
    flexWrap: 'wrap',  
    alignItems: 'flex-start',  
    ...shorthands.gap('10px'),  
    ...shorthands.padding('10px', '0'),  
  },  
  selectContainer: {  
    display: 'flex',  
    flexDirection: 'column',  
    minWidth: '200px',  
    ...shorthands.margin('0', '10px', '10px', '0'),  
  },  
  select: {  
    minWidth: '200px',  
  },  
});  
  
const SelectionControls = ({  
  teams,  
  games,  
  players,  
  positions,  
  selectedTeamId,  
  onTeamSelect,  
  selectedGameId,  
  onGameSelect,  
  selectedPlayerId,  
  onPlayerSelect,  
  selectedPosition,  
  onPositionSelect,  
  getOpponentName,  
}) => {  
  const classes = useStyles();  
  
  return (  
    <div className={classes.container}>  
      {/* Team Selection */}  
      <div className={classes.selectContainer}>  
        <Label htmlFor="select_team">Select a Team:</Label>  
        <Combobox  
          placeholder="Select a Team"  
          id="select_team"  
          value={teams.find((team) => team.id === selectedTeamId)?.name || ''}  
          onOptionSelect={(e, data) => {  
            onTeamSelect(data.optionValue);  
          }}  
          className={classes.select}  
        >  
          {teams.map((team) => (  
            <Option key={team.id} value={team.id}>  
              {team.name}  
            </Option>  
          ))}  
        </Combobox>  
      </div>  
  
      {selectedTeamId && (  
        <>  
          {/* Game Selection */}  
          <div className={classes.selectContainer}>  
            <Label htmlFor="select_game">Select a Game (Optional):</Label>  
            <Combobox  
              placeholder="Select a Game"  
              id="select_game"  
              value={  
                selectedGameId  
                  ? games.find((game) => game.id === selectedGameId)?.date || ''  
                  : ''  
              }  
              onOptionSelect={(e, data) => {  
                onGameSelect(data.optionValue);  
              }}  
              className={classes.select}  
            >  
              <Option value="">All Games</Option>  
              {games.map((game) => (  
                <Option key={game.id} value={game.id}>  
                  {`${game.date} - vs. ${getOpponentName(game)} (${game.gameType})`}  
                </Option>  
              ))}  
            </Combobox>  
          </div>  
  
          {/* Player Selection */}  
          <div className={classes.selectContainer}>  
            <Label htmlFor="select_player">Select a Player (Optional):</Label>  
            <Combobox  
              placeholder="Select a Player"  
              id="select_player"  
              value={players.find((p) => p.id === selectedPlayerId)?.name || ''}  
              onOptionSelect={(e, data) => {  
                onPlayerSelect(data.optionValue);  
              }}  
              className={classes.select}  
            >  
              <Option value="">All Players</Option>  
              {players  
                .filter((p) => p.teamId === selectedTeamId)  
                .map((player) => (  
                  <Option key={player.id} value={player.id}>  
                    {player.name}  
                  </Option>  
                ))}  
            </Combobox>  
          </div>  
  
          {/* Position Selection */}  
          <div className={classes.selectContainer}>  
            <Label htmlFor="select_position">Select a Position (Optional):</Label>  
            <Combobox  
              placeholder="Select a Position"  
              id="select_position"  
              value={selectedPosition || ''}  
              onOptionSelect={(e, data) => {  
                onPositionSelect(data.optionValue);  
              }}  
              className={classes.select}  
            >  
              <Option value="">All Positions</Option>  
              {positions.map((position) => (  
                <Option key={position} value={position}>  
                  {position}  
                </Option>  
              ))}  
            </Combobox>  
          </div>  
        </>  
      )}  
    </div>  
  );  
};  
  
SelectionControls.propTypes = {  
  teams: PropTypes.array.isRequired,  
  games: PropTypes.array.isRequired,  
  players: PropTypes.array.isRequired,  
  positions: PropTypes.array.isRequired,  
  selectedTeamId: PropTypes.string.isRequired,  
  onTeamSelect: PropTypes.func.isRequired,  
  selectedGameId: PropTypes.string.isRequired,  
  onGameSelect: PropTypes.func.isRequired,  
  selectedPlayerId: PropTypes.string.isRequired,  
  onPlayerSelect: PropTypes.func.isRequired,  
  selectedPosition: PropTypes.string.isRequired,  
  onPositionSelect: PropTypes.func.isRequired,  
  getOpponentName: PropTypes.func.isRequired,  
};  
  
export default SelectionControls;  
