// frontend/src/controllers/UserController.js  
/**  
 * File Name: UserController.js  
 * Location: frontend/src/controllers  
 * Description: Controller handling user-related data operations with backend integration.  
 */  
  
import api from '../api';  
  
class UserController {  
  // Fetch user profile from backend  
  async getUserProfile() {  
    try {  
      const response = await api.get('/users/me');  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching user profile:', error);  
      throw error;  
    }  
  }  
  
  // Update user profile  
  async updateUserProfile(userData) {  
    try {  
      const response = await api.put('/users/me', userData);  
      return response.data;  
    } catch (error) {  
      console.error('Error updating user profile:', error);  
      throw error;  
    }  
  }  
}  
  
const userControllerInstance = new UserController();  
export default userControllerInstance;  
