// frontend/src/contexts/AuthContext.js  
import React, { createContext, useState, useEffect } from 'react';  
  
export const AuthContext = createContext();  
  
const AuthProvider = ({ children }) => {  
  const [authData, setAuthData] = useState({  
    isAuthenticated: !!localStorage.getItem('accessToken'),  
    user: JSON.parse(localStorage.getItem('user')) || null,  
    sessionExpired: false,  
  });  
  
  // Listen for session expiration events  
  useEffect(() => {  
    const handleSessionExpired = () => {  
      setAuthData({  
        isAuthenticated: false,  
        user: null,  
        sessionExpired: true,  
      });  
      localStorage.removeItem('accessToken');  
      localStorage.removeItem('refreshToken');  
      localStorage.removeItem('user');  
    };  
  
    window.addEventListener('sessionExpired', handleSessionExpired);  
  
    return () => {  
      window.removeEventListener('sessionExpired', handleSessionExpired);  
    };  
  }, []);  
  
  // Polling to check for changes in localStorage  
  useEffect(() => {  
    const intervalId = setInterval(() => {  
      const accessToken = localStorage.getItem('accessToken');  
      if (!accessToken && authData.isAuthenticated) {  
        // Access token was removed  
        setAuthData({  
          isAuthenticated: false,  
          user: null,  
          sessionExpired: false,  
        });  
      }  
    }, 1000); // Check every second  
  
    return () => {  
      clearInterval(intervalId);  
    };  
  }, [authData.isAuthenticated]);  
  
  const login = (userData, accessToken, refreshToken) => {  
    setAuthData({  
      isAuthenticated: true,  
      user: userData,  
      sessionExpired: false,  
    });  
    localStorage.setItem('accessToken', accessToken);  
    localStorage.setItem('refreshToken', refreshToken);  
    localStorage.setItem('user', JSON.stringify(userData));  
  };  
  
  const logout = () => {  
    setAuthData({  
      isAuthenticated: false,  
      user: null,  
      sessionExpired: false,  
    });  
    localStorage.removeItem('accessToken');  
    localStorage.removeItem('refreshToken');  
    localStorage.removeItem('user');  
  };  
  
  return (  
    <AuthContext.Provider value={{ ...authData, login, logout }}>  
      {children}  
    </AuthContext.Provider>  
  );  
};  
  
export default AuthProvider;  
