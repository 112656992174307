// frontend/src/hooks/useEventLog.js  
/**  
 * File Name: useEventLog.js  
 * Location: src/hooks  
 * Description: Custom hook to manage event log and related state, ensuring the scoreboard is built from the event log.  
 */  
  
import { useState, useEffect } from 'react';  
import EventController from '../controllers/EventController';  
import { v4 as uuidv4 } from 'uuid';  
  
const useEventLog = (  
  gameId,  
  gameType,  
  myTeamId,  
  assignedPlayers,  
  assignedPlayerIds,  
  positions,  
  currentQuarter,  
  teams,  
  game,  
  setCurrentQuarter  
) => {  
  const [eventLog, setEventLog] = useState([]);  
  const [playerStats, setPlayerStats] = useState({});  
  const [homeTeamScores, setHomeTeamScores] = useState([0, 0, 0, 0]);  
  const [awayTeamScores, setAwayTeamScores] = useState([0, 0, 0, 0]);  
  const [specialHomeScore, setSpecialHomeScore] = useState(0);  
  const [specialAwayScore, setSpecialAwayScore] = useState(0);  
  
  const [eventsData, setEventsData] = useState([]);  
  
  /**  
   * Effect to fetch events when gameId and game data are available.  
   * Ensures that events are fetched only after game data is loaded to prevent issues with the scoreboard.  
   */  
  useEffect(() => {  
    if (gameId && game) {  
      fetchEvents(gameId);  
    } else {  
      // Reset states if no game is selected or game data is not yet available  
      setEventLog([]);  
      setPlayerStats({});  
      setHomeTeamScores([0, 0, 0, 0]);  
      setAwayTeamScores([0, 0, 0, 0]);  
      setSpecialHomeScore(0);  
      setSpecialAwayScore(0);  
    }  
  }, [gameId, game]);  
  
  /**  
   * Effect to rebuild state from events whenever the game or eventsData changes.  
   * Ensures that the scoreboard and other states are correctly updated.  
   */  
  useEffect(() => {  
    if (game && eventsData.length >= 0) {  
      rebuildStateFromEvents(eventsData);  
    }  
  }, [game, eventsData]);  
  
  /**  
   * Fetches events for the given gameId.  
   * @param {string} gameId - The ID of the game to fetch events for.  
   */  
  const fetchEvents = async (gameId) => {  
    try {  
      const data = await EventController.getEventsByGameId(gameId);  
      setEventsData(data);  
    } catch (error) {  
      console.error('Error fetching events:', error);  
    }  
  };  
  
  /**  
   * Rebuilds the state from the fetched eventsData.  
   * Updates event log, player stats, and team scores based on the events.  
   * @param {Array} eventsData - The array of event objects fetched from the backend.  
   */  
  const rebuildStateFromEvents = (eventsData) => {  
    if (!game) {  
      console.error('Game data not available');  
      return;  
    }  
  
    let newEventLog = [];  
    let newPlayerStats = {};  
    let newHomeTeamScores = [0, 0, 0, 0];  
    let newAwayTeamScores = [0, 0, 0, 0];  
    let specialHomeScore = 0;  
    let specialAwayScore = 0;  
    let maxQuarter = 1;  
  
    eventsData.forEach((event) => {  
      const {  
        id,  
        eventType,  
        quarter,  
        timestamp,  
        position,  
        playerName,  
        points = 1,  
      } = event;  
      const eventPoints = points;  
      // Determine if your team is the home or away team  
      const ourTeamIsHomeTeam = myTeamId === game.homeTeamId;  
      const ourTeamIsAwayTeam = myTeamId === game.awayTeamId;  
  
      const eventDate = new Date(timestamp);  
      const formattedTime = eventDate.toLocaleTimeString([], {  
        hour12: false,  
      });  
  
      let logEntry = '';  
  
      // Update maxQuarter if this event's quarter is higher (within 1-4)  
      if (quarter >= 1 && quarter <= 4 && quarter > maxQuarter) {  
        maxQuarter = quarter;  
      }  
  
      switch (eventType) {  
        case 'Goal':  
          if (playerName) {  
            if (!newPlayerStats[playerName]) {  
              newPlayerStats[playerName] = { goals: 0, shots: 0, points: 0 };  
            }  
            newPlayerStats[playerName].goals += 1;  
            newPlayerStats[playerName].shots += 1;  
            newPlayerStats[playerName].points += eventPoints;  
          }  
          // Update your team's score  
          if (quarter >= 1 && quarter <= 4) {  
            // Regular quarters  
            if (ourTeamIsHomeTeam) {  
              newHomeTeamScores[quarter - 1] += eventPoints;  
            } else if (ourTeamIsAwayTeam) {  
              newAwayTeamScores[quarter - 1] += eventPoints;  
            }  
          } else {  
            // Special quarter  
            if (ourTeamIsHomeTeam) {  
              specialHomeScore += eventPoints;  
            } else if (ourTeamIsAwayTeam) {  
              specialAwayScore += eventPoints;  
            }  
          }  
          logEntry = `Q${quarter} ${formattedTime} - ${eventPoints}-point Goal by ${playerName} (${position})`;  
          break;  
  
        case 'MissedShot':  
          if (playerName) {  
            if (!newPlayerStats[playerName]) {  
              newPlayerStats[playerName] = { goals: 0, shots: 0, points: 0 };  
            }  
            newPlayerStats[playerName].shots += 1;  
          }  
          logEntry = `Q${quarter} ${formattedTime} - Missed Shot by ${playerName} (${position})`;  
          break;  
  
        case 'TurnoverFor':  
          logEntry = `Q${quarter} ${formattedTime} - Turnover For`;  
          break;  
  
        case 'TurnoverAgainst':  
          logEntry = `Q${quarter} ${formattedTime} - Turnover Against`;  
          break;  
  
        case 'OpponentGoal':  
          // Update opponent's score  
          if (quarter >= 1 && quarter <= 4) {  
            // Regular quarters  
            if (ourTeamIsHomeTeam) {  
              newAwayTeamScores[quarter - 1] += eventPoints;  
            } else if (ourTeamIsAwayTeam) {  
              newHomeTeamScores[quarter - 1] += eventPoints;  
            }  
          } else {  
            // Special quarter  
            if (ourTeamIsHomeTeam) {  
              specialAwayScore += eventPoints;  
            } else if (ourTeamIsAwayTeam) {  
              specialHomeScore += eventPoints;  
            }  
          }  
          logEntry = `Q${quarter} ${formattedTime} - Opponent Scored ${eventPoints} Point${eventPoints > 1 ? 's' : ''}`;  
          break;  
  
        case 'PositionAssignment':  
          logEntry = `Q${quarter} ${formattedTime} - Assigned ${playerName} to ${position}`;  
          break;  
  
        case 'PositionUnassignment':  
          logEntry = `Q${quarter} ${formattedTime} - Unassigned ${playerName} from ${position}`;  
          break;  
  
        default:  
          break;  
      }  
  
      if (logEntry) {  
        newEventLog.push({ id, description: logEntry });  
      }  
    });  
  
    // Update the current quarter based on the highest quarter from events  
    setCurrentQuarter(maxQuarter);  
  
    // Update states  
    setEventLog(newEventLog);  
    setPlayerStats(newPlayerStats);  
    setHomeTeamScores(newHomeTeamScores);  
    setAwayTeamScores(newAwayTeamScores);  
    setSpecialHomeScore(specialHomeScore);  
    setSpecialAwayScore(specialAwayScore);  
  };  
  
  /**  
   * Records a new event and updates the state accordingly.  
   * @param {string} eventType - The type of event (e.g., 'Goal', 'MissedShot').  
   * @param {string} [position=null] - The position of the player involved.  
   * @param {number} [points=null] - Points scored in the event.  
   */  
  const recordEvent = async (eventType, position = null, points = null) => {  
    if (!game) {  
      alert('Please select a game before recording events.');  
      return;  
    }  
  
    const timestamp = new Date().toISOString();  
    let logEntry = '';  
  
    if (!points) {  
      points = 1; // Default to 1 point if not specified  
    }  
  
    const ourTeamIsHomeTeam = myTeamId === game.homeTeamId;  
    const ourTeamIsAwayTeam = myTeamId === game.awayTeamId;  
  
    switch (eventType) {  
      case 'Goal':  
        {  
          const shooter = position;  
          if (shooter && positions.includes(shooter)) {  
            const playerName = assignedPlayers[shooter];  
            if (playerName) {  
              // Update player stats  
              setPlayerStats((prev) => {  
                const stats = prev[playerName] || {  
                  goals: 0,  
                  shots: 0,  
                  points: 0,  
                };  
                return {  
                  ...prev,  
                  [playerName]: {  
                    ...stats,  
                    shots: stats.shots + 1,  
                    goals: stats.goals + 1,  
                    points: stats.points + points,  
                  },  
                };  
              });  
  
              // Update our team's score  
              if (currentQuarter >= 1 && currentQuarter <= 4) {  
                // Regular quarters  
                if (ourTeamIsHomeTeam) {  
                  setHomeTeamScores((prev) => {  
                    const updatedScores = [...prev];  
                    updatedScores[currentQuarter - 1] += points;  
                    return updatedScores;  
                  });  
                } else if (ourTeamIsAwayTeam) {  
                  setAwayTeamScores((prev) => {  
                    const updatedScores = [...prev];  
                    updatedScores[currentQuarter - 1] += points;  
                    return updatedScores;  
                  });  
                }  
              } else {  
                // Special quarter  
                if (ourTeamIsHomeTeam) {  
                  setSpecialHomeScore((prev) => prev + points);  
                } else if (ourTeamIsAwayTeam) {  
                  setSpecialAwayScore((prev) => prev + points);  
                }  
              }  
  
              logEntry = `Q${currentQuarter} ${timestamp} - ${points}-point Goal by ${playerName} (${shooter})`;  
            } else {  
              alert(`No player assigned to position ${shooter}`);  
              return;  
            }  
          } else {  
            alert('Invalid position for shot.');  
            return;  
          }  
        }  
        break;  
  
      case 'MissedShot':  
        {  
          const shooter = position;  
          if (shooter && positions.includes(shooter)) {  
            const playerName = assignedPlayers[shooter];  
            if (playerName) {  
              // Update player stats  
              setPlayerStats((prev) => {  
                const stats = prev[playerName] || {  
                  goals: 0,  
                  shots: 0,  
                  points: 0,  
                };  
                return {  
                  ...prev,  
                  [playerName]: {  
                    ...stats,  
                    shots: stats.shots + 1,  
                  },  
                };  
              });  
  
              logEntry = `Q${currentQuarter} ${timestamp} - Missed Shot by ${playerName} (${shooter})`;  
            } else {  
              alert(`No player assigned to position ${shooter}`);  
              return;  
            }  
          } else {  
            alert('Invalid position for shot.');  
            return;  
          }  
        }  
        break;  
  
      case 'TurnoverFor':  
        logEntry = `Q${currentQuarter} ${timestamp} - Turnover For`;  
        break;  
  
      case 'TurnoverAgainst':  
        logEntry = `Q${currentQuarter} ${timestamp} - Turnover Against`;  
        break;  
  
      case 'OpponentGoal':  
        // Update opponent's score  
        if (currentQuarter >= 1 && currentQuarter <= 4) {  
          // Regular quarters  
          if (ourTeamIsHomeTeam) {  
            setAwayTeamScores((prev) => {  
              const updatedScores = [...prev];  
              updatedScores[currentQuarter - 1] += points;  
              return updatedScores;  
            });  
          } else if (ourTeamIsAwayTeam) {  
            setHomeTeamScores((prev) => {  
              const updatedScores = [...prev];  
              updatedScores[currentQuarter - 1] += points;  
              return updatedScores;  
            });  
          }  
        } else {  
          // Special quarter  
          if (ourTeamIsHomeTeam) {  
            setSpecialAwayScore((prev) => prev + points);  
          } else if (ourTeamIsAwayTeam) {  
            setSpecialHomeScore((prev) => prev + points);  
          }  
        }  
        logEntry = `Q${currentQuarter} ${timestamp} - Opponent Scored ${points} Point${points > 1 ? 's' : ''}`;  
        break;  
  
      default:  
        break;  
    }  
  
    if (logEntry) {  
      const newEvent = {  
        id: uuidv4(),  
        eventType,  
        quarter: currentQuarter,  
        timestamp,  
        position,  
        playerName: assignedPlayers[position] || null,  
        playerId: assignedPlayerIds[position] || null,  
        points,  
        gameType,  
        gameId: gameId,  
        teamId: myTeamId, // Include teamId  
      };  
  
      setEventLog((prev) => [...prev, { id: newEvent.id, description: logEntry }]);  
  
      // Save event to backend  
      try {  
        await EventController.saveEvent(newEvent);  
      } catch (error) {  
        console.error('Error saving event:', error);  
      }  
    }  
  };  
  
  /**  
   * Deletes an event and rebuilds the state.  
   * @param {string} eventId - The ID of the event to delete.  
   */  
  const deleteEvent = async (eventId) => {  
    try {  
      await EventController.deleteEvent(eventId, gameId);  
  
      // Re-fetch events to rebuild state  
      fetchEvents(gameId);  
    } catch (error) {  
      console.error('Error deleting event:', error);  
    }  
  };  
  
  // Return the state and functions  
  return {  
    eventLog,  
    playerStats,  
    homeTeamScores,  
    awayTeamScores,  
    specialHomeScore,  
    specialAwayScore,  
    recordEvent,  
    deleteEvent,  
    fetchEvents,  
  };  
};  
  
export default useEventLog;  
