// frontend/src/controllers/TeamController.js  
/**  
 * File Name: TeamController.js  
 * Location: frontend/src/controllers  
 * Description: Controller handling team-related data operations with backend integration, includes fetching all teams.  
 */  
  
import api from '../api';  
  
class TeamController {  
  // Fetch all teams from the backend  
  async getAllTeams() {  
    try {  
      const response = await api.get('/teams');  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
      throw error;  
    }  
  }  
  
  // Fetch a team by ID  
    async getTeamById(id) {  
      try {  
        const response = await api.get(`/teams/${id}`);  
        return response.data;  
      } catch (error) {  
        console.error(`Error fetching team with id ${id}:`, error);  
        throw error;  
      }  
    }  
  
  // Add a new team  
  async addTeam(team) {  
    try {  
      const response = await api.post('/teams', team);  
      return response.data;  
    } catch (error) {  
      console.error('Error adding team:', error);  
      throw error;  
    }  
  }  
  
  // Update an existing team  
  async updateTeam(team) {  
    try {  
      const response = await api.put(`/teams/${team.id}`, team);  
      return response.data;  
    } catch (error) {  
      console.error('Error updating team:', error);  
      throw error;  
    }  
  }  
  
  // Delete a team  
  async deleteTeam(id) {  
    try {  
      await api.delete(`/teams/${id}`);  
    } catch (error) {  
      console.error('Error deleting team:', error);  
      throw error;  
    }  
  }  
}  
  
const teamControllerInstance = new TeamController();  
export default teamControllerInstance;  
