import axios from 'axios';  
  
// Load base URL from .env file or use default  
const baseURL =  
  process.env.REACT_APP_API_URL ||  
  'https://rlcourtsidebackend.jollyhill-33d077cf.australiaeast.azurecontainerapps.io';  
  
const api = axios.create({  
  baseURL: `${baseURL}/api`,  
});  
  
let isRefreshing = false;  
let failedQueue = [];  
  
const processQueue = (error, token = null) => {  
  failedQueue.forEach((prom) => {  
    if (error) {  
      prom.reject(error);  
    } else {  
      prom.resolve(token);  
    }  
  });  
  
  failedQueue = [];  
};  
  
// Add request interceptor to include auth token  
api.interceptors.request.use(  
  (config) => {  
    const token = localStorage.getItem('accessToken');  
    if (token) {  
      config.headers['Authorization'] = `Bearer ${token}`;  
    }  
    return config;  
  },  
  (error) => Promise.reject(error)  
);  
  
// Add response interceptor to handle token refresh  
api.interceptors.response.use(  
  (response) => response,  
  (error) => {  
    const originalRequest = error.config;  
  
    // If error is 401 Unauthorized and request has not been retried yet  
    if (  
      error.response &&  
      error.response.status === 401 &&  
      !originalRequest._retry  
    ) {  
      if (isRefreshing) {  
        return new Promise(function (resolve, reject) {  
          failedQueue.push({ resolve, reject });  
        })  
          .then((token) => {  
            originalRequest.headers['Authorization'] = 'Bearer ' + token;  
            return api(originalRequest);  
          })  
          .catch((err) => {  
            return Promise.reject(err);  
          });  
      }  
  
      originalRequest._retry = true;  
      isRefreshing = true;  
  
      const refreshToken = localStorage.getItem('refreshToken');  
  
      if (!refreshToken) {    
        // Dispatch session expired event    
        window.dispatchEvent(new Event('sessionExpired'));    
        return Promise.reject(error);    
      }  
      
        
  
      return new Promise(function (resolve, reject) {  
        axios  
          .post(`${baseURL}/api/auth/token`, {  
            refreshToken: refreshToken,  
          })  
          .then(({ data }) => {  
            localStorage.setItem('accessToken', data.accessToken);  
            api.defaults.headers.common['Authorization'] =  
              'Bearer ' + data.accessToken;  
            originalRequest.headers['Authorization'] =  
              'Bearer ' + data.accessToken;  
            processQueue(null, data.accessToken);  
            resolve(api(originalRequest));  
          })  
          .catch((err) => {    
            processQueue(err, null);    
            isRefreshing = false;    
            // Dispatch session expired event    
            window.dispatchEvent(new Event('sessionExpired'));    
            reject(err);    
          });  
      });  
    }  
  
    return Promise.reject(error);  
  }  
);  
  
export default api;  
