// frontend/src/pages/UnauthorizedPage.js  
/**  
 * File Name: UnauthorizedPage.js  
 * Location: src/pages  
 * Description: Displays a message when a user tries to access a page without proper authorization.  
 */  
  
import React from 'react';  
import { useNavigate } from 'react-router-dom';  
import { Button, makeStyles, shorthands } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  container: {  
    ...shorthands.padding('40px'),  
    textAlign: 'center',  
  },  
  message: {  
    fontSize: '1.5rem',  
    marginBottom: '20px',  
  },  
  button: {  
    marginTop: '20px',  
  },  
});  
  
function UnauthorizedPage() {  
  const styles = useStyles();  
  const navigate = useNavigate();  
  
  const handleGoBack = () => {  
    navigate(-1); // Navigate back to the previous page  
  };  
  
  const handleGoHome = () => {  
    navigate('/dashboard'); // Navigate to the dashboard or home page  
  };  
  
  return (  
    <div className={styles.container}>  
      <h1 className={styles.message}>Unauthorized Access</h1>  
      <p>You do not have permission to view this page.</p>  
      <div>  
        <Button className={styles.button} onClick={handleGoBack}>  
          Go Back  
        </Button>  
        <Button className={styles.button} appearance="primary" onClick={handleGoHome}>  
          Return to Dashboard  
        </Button>  
      </div>  
    </div>  
  );  
}  
  
export default UnauthorizedPage;  