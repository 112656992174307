// frontend/src/hooks/useEvents.js    
/**    
 * File Name: useEvents.js    
 * Location: src/hooks    
 * Description: Custom hook to fetch and process events data, adjusted to handle position filtering.    
 */    
    
import { useState, useEffect } from 'react';    
import EventController from '../controllers/EventController';    
import GameController from '../controllers/GameController';    
    
export const useEvents = (teamId, gameId, players, selectedPosition) => {    
  const [events, setEvents] = useState([]);    
  const [loading, setLoading] = useState(false); // Loading state    
  const [error, setError] = useState(null); // Error state    
    
  useEffect(() => {    
    if (!players) {    
      setEvents([]);    
      return;    
    }    
    
    const fetchEvents = async () => {    
      try {    
        setLoading(true);    
    
        if (gameId) {    
          // Fetch events for the selected game    
          const eventsData = await EventController.getEventsByGameId(gameId);    
          const gameData = await GameController.getGameById(gameId);    
          const processedEvents = processEvents(eventsData, teamId, players, gameData, selectedPosition);    
          setEvents(processedEvents);    
        } else if (teamId) {    
          // Fetch events for all games of the team    
          const gamesData = await GameController.getGamesByTeamId(teamId);    
          let allEvents = [];    
          for (const game of gamesData) {    
            const eventsData = await EventController.getEventsByGameId(game.id);    
            const processedEvents = processEvents(eventsData, teamId, players, game, selectedPosition);    
            allEvents = allEvents.concat(processedEvents);    
          }    
          setEvents(allEvents);    
        } else {    
          // No teamId selected, fetch events for all games    
          const gamesData = await GameController.getAllGames();    
          let allEvents = [];    
          for (const game of gamesData) {    
            const eventsData = await EventController.getEventsByGameId(game.id);    
            const processedEvents = processEvents(eventsData, null, players, game, selectedPosition);    
            allEvents = allEvents.concat(processedEvents);    
          }    
          setEvents(allEvents);    
        }    
      } catch (error) {    
        console.error('Error fetching events:', error);    
        setError(error);    
      } finally {    
        setLoading(false);    
      }    
    };    
    
    fetchEvents();    
  }, [teamId, gameId, players, selectedPosition]);    
    
  return { events, loading, error };    
};    
    
// Helper function to process events    
  
const processEvents = (eventsData, teamId, players, game, selectedPosition) => {    
  
  const processedEvents = eventsData    
    .filter((event) => {    
      // Filter by position if selectedPosition is provided    
      if (selectedPosition) {    
        return event.position === selectedPosition;    
      }    
      return true;    
    })    
    .map((event) => {    
      let isSelectedTeamEvent = true; // Consider all events relevant when no team is selected    
  
      if (teamId) {    
        // Determine if the event is from the selected team    
        if (event.playerId) {    
          // Find the player and check their teamId    
          const player = players.find((p) => p.id === event.playerId);    
          if (player && player.teamId === teamId) {    
            isSelectedTeamEvent = true;    
          } else {    
            isSelectedTeamEvent = false;    
          }    
        } else {    
          // Infer based on eventType    
          switch (event.eventType) {    
            case 'OpponentGoal':    
              // OpponentGoal always from opponent    
              isSelectedTeamEvent = false;    
              break;    
            case 'Goal':    
            case 'Shot':    
            case 'MissedShot':    
            case 'TurnoverFor':    
            case 'TurnoverAgainst':    
              // These events are from our team    
              isSelectedTeamEvent = true;    
              break;    
            default:    
              // Any other events are considered opponent events    
              isSelectedTeamEvent = false;    
              break;    
          }    
        }    
      }    
  
      // Define isSelectedTeamHome based on teamId and game.homeTeamId  
      const isSelectedTeamHome = teamId === game.homeTeamId;  
  
      return {    
        ...event,    
        gameType: game.gameType,    
        teamId: teamId,    
        opponentTeamId: teamId ? (isSelectedTeamHome ? game.awayTeamId : game.homeTeamId) : null,    
        isSelectedTeamEvent,    
      };    
    });    
  
  return processedEvents;    
};    
