/**  
 * File Name: Scoreboard.js  
 * Location: src/components  
 * Description: Component to display the game scoreboard.  
 */  
  
import React from 'react';  
import {  
  Table,  
  TableHeader,  
  TableRow,  
  TableCell,  
  TableBody,  
} from '@fluentui/react-components';  
import { makeStyles } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  scoreboard: {  
    marginTop: '20px',  
    overflowY: 'auto',  
    backgroundColor: 'var(--colorNeutralBackground2)',  
    border: `2px solid var(--colorNeutralStroke1)`,  
    borderRadius: 'var(--borderRadiusMedium)',  
    padding: '10px',  
    boxShadow: 'var(--shadow4)',  
  },  
});  
  
const Scoreboard = ({ homeTeamName, awayTeamName, homeTeamScores, awayTeamScores, specialHomeScore = 0, specialAwayScore = 0  }) => {  
  const styles = useStyles();  
  
  const totalHomeScore = homeTeamScores.reduce((a, b) => a + b, 0) + specialHomeScore; 
  const totalAwayScore = awayTeamScores.reduce((a, b) => a + b, 0) + specialAwayScore;   
  
  return (  
    <div className={styles.scoreboard}>  
      <h4>Scoreboard</h4>  
      <Table>  
        <TableHeader>  
          <TableRow>  
            <TableCell>Quarter</TableCell>  
            <TableCell>{homeTeamName}</TableCell>  
            <TableCell>{awayTeamName}</TableCell>  
          </TableRow>  
        </TableHeader>  
        <TableBody>  
          {Array.from({ length: 4 }, (_, i) => (  
            <TableRow key={`Q${i + 1}`}>  
              <TableCell>Q{i + 1}</TableCell>  
              <TableCell>{homeTeamScores[i] || 0}</TableCell>  
              <TableCell>{awayTeamScores[i] || 0}</TableCell>  
            </TableRow>  
          ))}  
          <TableRow>  
            <TableCell>Total</TableCell>  
            <TableCell>{totalHomeScore}</TableCell>  
            <TableCell>{totalAwayScore}</TableCell>  
          </TableRow>  
        </TableBody>  
      </Table>  
    </div>  
  );  
};  
  
export default Scoreboard;  
