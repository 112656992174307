// src/components/Footer.js  
/**  
 * File Name: Footer.js  
 * Location: src/components  
 * Description: Footer component using theme tokens for consistent styling.  
 */  
  
import React from 'react';  
import { makeStyles, tokens } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  footer: {  
    backgroundColor: tokens.colorNeutralBackground1,  
    padding: '10px',  
    textAlign: 'center',  
    color: tokens.colorNeutralForeground2,  
  },  
  link: {  
    color: tokens.colorNeutralForeground2,  
    textDecoration: 'none',  
    '&:hover': {  
      textDecoration: 'underline',  
    },  
  },  
});  
  
function Footer() {  
  const styles = useStyles();  
  
  return (  
    <footer className={styles.footer}>  
      <a  
        href="https://www.rhemlabs.com.au/"  
        target="_blank"  
        rel="noopener noreferrer"  
        className={styles.link}  
      >  
       &copy; RHEM Labs 2024  
      </a>  
    </footer>  
  );  
}  
  
export default Footer;  
