/**  
 * File Name: usePositions.js  
 * Location: src/hooks  
 * Description: Custom hook to manage positions based on game type.  
 */  
  
const usePositions = (gameType) => {  
    const positions =  
      gameType === '7-aside'  
        ? ['GS', 'GA', 'WA', 'C', 'WD', 'GD', 'GK']  
        : ['A1', 'A2', 'C1', 'C2', 'D1', 'D2'];  
    
    const positionOptions = {  
      A1: [  
        { type: 'Goal', points: 1, label: '1 Point' },  
        { type: 'Goal', points: 2, label: '2 Points' },  
        { type: 'MissedShot', label: 'Missed Shot' },  
      ],  
      A2: [  
        { type: 'Goal', points: 1, label: '1 Point' },  
        { type: 'Goal', points: 2, label: '2 Points' },  
        { type: 'MissedShot', label: 'Missed Shot' },  
      ],  
      C1: [  
        { type: 'Goal', points: 2, label: '2 Points' },  
        { type: 'MissedShot', label: 'Missed Shot' },  
      ],  
      C2: [  
        { type: 'Goal', points: 2, label: '2 Points' },  
        { type: 'MissedShot', label: 'Missed Shot' },  
      ],  
    };  
    
    return {  
      positions,  
      positionOptions,  
    };  
  };  
    
  export default usePositions;  
  