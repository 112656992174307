// frontend/src/components/ProtectedRoute.js  
import React, { useContext } from 'react';  
import { Navigate } from 'react-router-dom';  
import { AuthContext } from '../contexts/AuthContext';  
  
function ProtectedRoute({ children, requiredRoles }) {  
  const { isAuthenticated, user } = useContext(AuthContext);  
  
  if (!isAuthenticated) {  
    // User is not authenticated  
    // Redirect to login page  
    return <Navigate to="/" replace />;  
  }  
  
  if (requiredRoles && !requiredRoles.includes(user?.role)) {  
    return <Navigate to="/unauthorized" replace />;  
  }  
  
  return children;  
}  
  
export default ProtectedRoute;  
