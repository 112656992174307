// frontend/src/pages/AddEditTeamPage.js  
/**  
 * File Name: AddEditTeamPage.js  
 * Location: src/pages  
 * Description: Component for adding or editing a team's details, including an option to mark as an opponent.  
 */  
  
import React, { useState, useEffect } from 'react';  
import { Button, Input, Label, Checkbox, makeStyles, shorthands } from '@fluentui/react-components';  
import TeamController from '../controllers/TeamController';  
import { useNavigate, useLocation } from 'react-router-dom';  
  
const useStyles = makeStyles({  
  container: {  
    ...shorthands.padding('20px'),  
    maxWidth: '600px',  
    margin: '0 auto',  
  },  
  formField: {  
    marginBottom: '15px',  
  },  
});  
  
function AddEditTeamPage() {  
  const navigate = useNavigate();  
  const location = useLocation();  
  const [teamId, setTeamId] = useState(null);  
  const [teamName, setTeamName] = useState('');  
  const [division, setDivision] = useState('');  
  const [isOpponent, setIsOpponent] = useState(false);  
  const [isMyTeam, setIsMyTeam] = useState(false);  
  const styles = useStyles();  
  
  useEffect(() => {  
    if (location.state && location.state.id) {  
      fetchTeam(location.state.id);  
    }  
  }, [location.state]);  
  
  const fetchTeam = async (id) => {  
    try {  
      const team = await TeamController.getTeamById(id);  
      if (team) {  
        setTeamId(team.id);  
        setTeamName(team.name);  
        setDivision(team.division);  
        setIsOpponent(team.isOpponent || false); 
        setIsMyTeam(team.isMyTeam || false); 
      }  
    } catch (error) {  
      console.error('Error fetching team:', error);  
    }  
  };  
  
  const handleSave = async (event) => {  
    event.preventDefault();  
    const teamData = {  
      id: teamId || undefined,  
      name: teamName,  
      division: division,  
      isOpponent: isOpponent,  
      isMyTeam: isMyTeam,  
    };   
  
    try {  
      if (teamId) {  
        // Update existing team  
        await TeamController.updateTeam(teamData);  
      } else {  
        // Add new team  
        await TeamController.addTeam(teamData);  
      }  
      navigate('/manage_teams');  
    } catch (error) {  
      console.error('Error saving team:', error);  
    }  
  };  
  
  return (  
    <div className={styles.container}>  
      <h2>{teamId ? 'Edit Team' : 'Add Team'}</h2>  
      <form onSubmit={handleSave}>  
        <div className={styles.formField}>  
          <Label htmlFor="team_name">Team Name:</Label>  
          <Input  
            id="team_name"  
            value={teamName}  
            onChange={(e) => setTeamName(e.target.value)}  
            required  
          />  
        </div>  
        <div className={styles.formField}>  
          <Label htmlFor="division">Division:</Label>  
          <Input  
            id="division"  
            value={division}  
            onChange={(e) => setDivision(e.target.value)}  
            required  
          />  
        </div>  
        <div className={styles.formField}>  
          <Checkbox  
            label="Is Opponent Team"  
            checked={isOpponent}  
            onChange={(e, data) => setIsOpponent(data.checked)}  
          />  
        </div>  
        <div className={styles.formField}>  
          <Checkbox  
            label="This is my team"  
            checked={isMyTeam}  
            onChange={(e, data) => setIsMyTeam(data.checked)}  
          />  
        </div>  
        <Button type="submit" appearance="primary">  
          Save Team  
        </Button>  
      </form>  
    </div>  
  );  
}  
  
export default AddEditTeamPage;  
