// frontend/src/hooks/useGameData.js  
/**  
 * File Name: useGameData.js  
 * Location: src/hooks  
 * Description: Custom hook to manage game-related data and state. Ensures 'myTeamId' is correctly set based on team data.  
 */  
  
import { useState, useEffect } from 'react';  
import GameController from '../controllers/GameController';  
import TeamController from '../controllers/TeamController';  
  
const useGameData = (myTeamId) => {  
  const [gameId, setGameId] = useState(null);  
  const [game, setGame] = useState(null);  
  const [games, setGames] = useState([]);  
  const [teams, setTeams] = useState([]);  
  const [homeTeam, setHomeTeam] = useState(null);  
  const [awayTeam, setAwayTeam] = useState(null);  
  const [gameType, setGameType] = useState('7-aside');  
  
  /**  
   * Fetches teams and games when the component mounts.  
   */  
  useEffect(() => {  
    fetchTeams();  
    fetchGames();  
  }, []);  
  
  /**  
   * Fetches game data whenever the gameId changes.  
   */  
  useEffect(() => {  
    if (gameId) {  
      fetchGame(gameId);  
    } else {  
      setGame(null);  
      setGameType('7-aside');  
    }  
  }, [gameId]);  
  
  /**  
   * Fetches all teams and sets the state.  
   */  
  const fetchTeams = async () => {  
    try {  
      const teamsData = await TeamController.getAllTeams();  
      setTeams(teamsData);  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
    }  
  };  
  
  /**  
   * Fetches all games and sets the state.  
   */  
  const fetchGames = async () => {  
    try {  
      const gamesData = await GameController.getAllGames();  
      setGames(gamesData);  
    } catch (error) {  
      console.error('Error fetching games:', error);  
    }  
  };  
  
  /**  
   * Fetches the game data for a specific gameId and updates the state.  
   * Also sets 'myTeamId' based on whether the home or away team is marked as 'isMyTeam'.  
   * @param {string} id - The ID of the game to fetch.  
   */  
  const fetchGame = async (id) => {  
    try {  
      const gameData = await GameController.getGameById(id);  
      setGame(gameData);  
      setGameType(gameData.gameType || '7-aside');  
  
      // Fetch home team data  
      let homeTeamData = null;  
      if (gameData.homeTeamId) {  
        try {  
          homeTeamData = await TeamController.getTeamById(gameData.homeTeamId);  
        } catch (error) {  
          console.error(`Error fetching home team with id ${gameData.homeTeamId}:`, error);  
        }  
      }  
  
      // Fetch away team data  
      let awayTeamData = null;  
      if (gameData.awayTeamId === 'custom') {  
        awayTeamData = { id: 'custom', name: gameData.awayTeamName };  
      } else if (gameData.awayTeamId) {  
        try {  
          awayTeamData = await TeamController.getTeamById(gameData.awayTeamId);  
        } catch (error) {  
          console.error(`Error fetching away team with id ${gameData.awayTeamId}:`, error);  
        }  
      }  
  
      setHomeTeam(homeTeamData);  
      setAwayTeam(awayTeamData);  
  

    } catch (error) {  
      console.error('Error fetching game:', error);  
    }  
  };  
  
   
  // Return the state and functions  
  return {  
    gameId,  
    setGameId,  
    game,  
    games,  
    teams,  
    homeTeam,  
    awayTeam,  
    gameType,  
    setGameType,  
  };  
};  
  
export default useGameData;  
