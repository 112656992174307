// frontend/src/App.js  
/**    
 * File Name: App.js    
 * Location: src    
 * Description: Main application component handling routing, theming, and authentication with context provider.    
 */  
  
import React, { useState } from 'react';  
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  
import { FluentProvider } from '@fluentui/react-components';  
import { courtsideLightTheme, courtsideDarkTheme } from './theme';  
  
import LoginPage from './pages/LoginPage';  
import DashboardPage from './pages/DashboardPage';  
import ManagePlayersPage from './pages/ManagePlayersPage';  
import AddEditPlayerPage from './pages/AddEditPlayerPage';  
import ManageGamesPage from './pages/ManageGamesPage';  
import ScheduleGamePage from './pages/ScheduleGamePage';  
import RealTimeTrackingPage from './pages/RealTimeTrackingPage';  
import ViewStatisticsPage from './pages/ViewStatisticsPage';  
import SettingsPage from './pages/SettingsPage';  
import UnauthorizedPage from './pages/UnauthorizedPage'; 
import ManageTeamsPage from './pages/ManageTeamsPage';  
import AddEditTeamPage from './pages/AddEditTeamPage';  
  
import Layout from './components/Layout';  
import ProtectedRoute from './components/ProtectedRoute'; 
  
import AuthProvider from './contexts/AuthContext';  
  
function App() {  
  const [currentTheme, setCurrentTheme] = useState('light');  
  
  const handleThemeChange = (theme) => {  
    setCurrentTheme(theme);  
  };  
  
  const theme = currentTheme === 'light' ? courtsideLightTheme : courtsideDarkTheme;  
  
  return (  
    <FluentProvider theme={theme}>  
      <AuthProvider>  
        <Router>  
          <Routes>  
            <Route path="/" element={<LoginPage />} />  
            <Route path="/unauthorized" element={<UnauthorizedPage />} />  
            <Route  
              element={  
                <Layout onThemeChange={handleThemeChange} currentTheme={currentTheme} />  
              }  
            >  
              <Route  
                path="/dashboard"  
                element={  
                  <ProtectedRoute>  
                    <DashboardPage />  
                  </ProtectedRoute>  
                }  
              /> 
              <Route  
  path="/manage_teams"  
  element={  
    <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
      <ManageTeamsPage />  
    </ProtectedRoute>  
  }  
/>  
<Route  
  path="/add_edit_team"  
  element={  
    <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
      <AddEditTeamPage />  
    </ProtectedRoute>  
  }  
/>   
              <Route  
                path="/manage_players"  
                element={  
                  <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
                    <ManagePlayersPage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/add_edit_player"  
                element={  
                  <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
                    <AddEditPlayerPage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/manage_games"  
                element={  
                  <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
                    <ManageGamesPage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/schedule_game"  
                element={  
                  <ProtectedRoute requiredRoles={['Coach', 'Assistant Coach', 'Administrator']}>  
                    <ScheduleGamePage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/real_time_tracking"  
                element={  
                  <ProtectedRoute>  
                    <RealTimeTrackingPage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/view_statistics"  
                element={  
                  <ProtectedRoute>  
                    <ViewStatisticsPage />  
                  </ProtectedRoute>  
                }  
              />  
              <Route  
                path="/settings"  
                element={  
                  <ProtectedRoute>  
                    <SettingsPage />  
                  </ProtectedRoute>  
                }  
              />  
            </Route>  
          </Routes>  
        </Router>  
      </AuthProvider>  
    </FluentProvider>  
  );  
}  
  
export default App;  
