/**  
 * File Name: utils.js  
 * Location: src/utils  
 * Description: Utility functions used across the application.  
 */  
  
export const getQuarterDuration = (gameType) => {  
    switch (gameType) {  
      case '6-aside':  
        return 10 * 60; // 10 minutes in seconds  
      case '7-aside':  
        return 12 * 60; // 12 minutes in seconds  
      default:  
        return 15 * 60; // Default 15 minutes  
    }  
  };  
    
  export const getTeamName = (teamId, teams, customName) => {  
    if (teamId === 'custom') {  
      return customName || 'Unknown Opponent';  
    }  
    const team = teams.find((t) => String(t.id) === String(teamId));  
    return team ? team.name : 'Unknown Team';  
  };  
  