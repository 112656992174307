// frontend/src/pages/DashboardPage.js  
/**    
 * File Name: DashboardPage.js    
 * Location: src/pages    
 * Description: Dashboard page with updated theme and visual enhancements.    
 */    
  
import React from 'react';    
import { useNavigate } from 'react-router-dom';    
import { Card, makeStyles, shorthands, tokens } from '@fluentui/react-components';    
// Import Fluent Icons    
import {    
  PeopleTeam24Regular as ManagePlayersIcon,    
  CalendarAgenda24Regular as ManageGamesIcon,    
  Timer24Regular as TrackGameIcon,    
  DataPie24Regular as ViewStatisticsIcon,    
  Settings24Regular as SettingsIcon,    
} from '@fluentui/react-icons';    
  
const useStyles = makeStyles({    
  container: {    
    ...shorthands.padding('20px'),    
  },    
  grid: {    
    display: 'flex',    
    flexWrap: 'wrap',    
    gap: '20px',    
  },    
  card: {    
    width: '200px',    
    textAlign: 'center',    
    cursor: 'pointer',    
    ...shorthands.padding('20px'),    
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),    
    ...shorthands.borderRadius('8px'),    
    backgroundColor: tokens.colorNeutralBackground1,    
    '&:hover': {    
      backgroundColor: tokens.colorNeutralBackground1Hover,    
    },    
  },    
  cardTitle: {    
    fontWeight: '600',    
    marginTop: '10px',    
    color: tokens.colorNeutralForeground1, // Use Fluent UI token    
  },    
  cardIcon: {    
    fontSize: '48px',    
    color: tokens.colorBrand1, // Use Fluent UI token    
  },    
});    
  
function DashboardPage() {    
  const styles = useStyles();    
  const navigate = useNavigate();    
  
  const dashboardOptions = [    
    { path: '/manage_players', label: 'Manage Players', icon: <ManagePlayersIcon /> },    
    { path: '/manage_games', label: 'Manage Games', icon: <ManageGamesIcon /> },    
    { path: '/real_time_tracking', label: 'Track Game', icon: <TrackGameIcon /> },    
    { path: '/view_statistics', label: 'View Statistics', icon: <ViewStatisticsIcon /> },    
    { path: '/settings', label: 'Settings', icon: <SettingsIcon /> },    
  ];    
  
  return (    
    <div className={styles.container}>    
      <h2>Welcome, Coach!</h2>    
      <div className={styles.grid}>    
        {dashboardOptions.map((option) => (    
          <Card    
            key={option.path}    
            className={styles.card}    
            onClick={() => navigate(option.path)}    
          >    
            <div className={styles.cardIcon}>{option.icon}</div>    
            <div className={styles.cardTitle}>{option.label}</div>    
          </Card>    
        ))}    
      </div>    
    </div>    
  );    
}    
  
export default DashboardPage;    
