// frontend/src/controllers/EventController.js  
/**  
 * File Name: EventController.js  
 * Location: frontend/src/controllers  
 * Description: Controller handling event-related data operations with backend integration.  
 */  
  
import api from '../api';  
  
class EventController {  
  // Save an event  
  async saveEvent(eventData) {  
    try {  
      const response = await api.post('/events', eventData);  
      return response.data;  
    } catch (error) {  
      console.error('Error saving event:', error);  
      throw error;  
    }  
  }  
  
  // Fetch events for a game  
  async getEventsByGameId(gameId) {  
    try {  
      const response = await api.get(`/events/game/${gameId}`);  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching events:', error);  
      throw error;  
    }  
  }  
  
  // Fetch an event by ID  
  async getEventById(id, gameId) {  
    try {  
      const response = await api.get(`/events/${gameId}/${id}`);  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching event:', error);  
      throw error;  
    }  
  }  
  
  // Delete an event  
  async deleteEvent(id, gameId) {  
    try {  
      await api.delete(`/events/${gameId}/${id}`);  
    } catch (error) {  
      console.error('Error deleting event:', error);  
      throw error;  
    }  
  }  
  
  // Fetch all events  
  async getAllEvents() {  
    try {  
      const response = await api.get('/events');  
      return response.data;  
    } catch (error) {  
      console.error('Error fetching all events:', error);  
      throw error;  
    }  
  }  
}  
  
const eventControllerInstance = new EventController();  
export default eventControllerInstance;  
