// frontend/src/pages/ManageGamesPage.js  
/**    
 * File Name: ManageGamesPage.js    
 * Location: frontend/src/pages    
 * Description: Component for managing scheduled games, fetches data from the backend.    
 */    
  
import React, { useEffect, useState } from 'react';    
import {    
  Button,    
  Table,    
  TableHeader,    
  TableRow,    
  TableCell,    
  TableBody,    
  Dialog,    
  DialogSurface,    
  DialogTitle,    
  DialogContent,    
  DialogActions,    
  makeStyles,    
  tokens,    
  shorthands,    
} from '@fluentui/react-components';    
import { useNavigate } from 'react-router-dom';    
import GameController from '../controllers/GameController';    
import TeamController from '../controllers/TeamController'; // Import TeamController  
  
const useStyles = makeStyles({    
  container: {    
    ...shorthands.padding('20px'),    
  },    
  addButton: {    
    marginBottom: '20px',    
  },    
  actionButtons: {    
    display: 'flex',    
    gap: '10px',    
  },    
});    
  
function ManageGamesPage() {    
  const navigate = useNavigate();    
  const [games, setGames] = useState([]);    
  const [teams, setTeams] = useState([]); // State to hold teams data  
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);    
  const [gameToDelete, setGameToDelete] = useState(null);    
  const styles = useStyles();    
  
  useEffect(() => {    
    fetchTeams(); // Fetch teams first  
  }, []);  
  
  const fetchTeams = async () => {  
    try {  
      const teamsData = await TeamController.getAllTeams();  
      setTeams(teamsData);  
      fetchGames(); // Fetch games after teams are loaded  
    } catch (error) {  
      console.error('Error fetching teams:', error);  
    }  
  };  
  
  const fetchGames = async () => {    
    try {    
      const gamesData = await GameController.getAllGames();    
      setGames(gamesData);    
    } catch (error) {    
      console.error('Error fetching games:', error);    
    }    
  };    
  
  const handleEdit = (id) => {    
    navigate('/schedule_game', { state: { id } });    
  };    
  
  const handleDeleteClick = (game) => {    
    setGameToDelete(game);    
    setDeleteConfirmOpen(true);    
  };    
  
  const handleDeleteConfirm = async () => {    
    try {    
      await GameController.deleteGame(gameToDelete.id);    
      fetchGames();    
      setDeleteConfirmOpen(false);    
    } catch (error) {    
      console.error('Error deleting game:', error);    
    }    
  };    
  
  const handleTrackGame = (id) => {    
    navigate('/real_time_tracking', { state: { gameId: id } });    
  };    
  
  // Helper function to get team name by ID  
  const getTeamName = (teamId) => {  
    if (teamId === 'custom') {  
      return null; // We will handle 'custom' case separately  
    }  
    const team = teams.find((t) => t.id === teamId);  
    return team ? team.name : 'Unknown Team';  
  };  
  
  return (    
    <div className={styles.container}>    
      <h2>Manage Games</h2>    
      <Button    
        onClick={() => navigate('/schedule_game')}    
        appearance="primary"    
        className={styles.addButton}    
      >    
        Schedule New Game    
      </Button>    
      <Table>    
        <TableHeader>    
          <TableRow>    
            <TableCell>Date</TableCell>    
            <TableCell>Home Team</TableCell>  
            <TableCell>Away Team</TableCell>  
            <TableCell>Location</TableCell>    
            <TableCell>Actions</TableCell>    
          </TableRow>    
        </TableHeader>    
        <TableBody>    
          {games.map((game) => {    
            // Get home and away team names  
            const homeTeamName = getTeamName(game.homeTeamId);  
            let awayTeamName = '';  
  
            if (game.awayTeamId === 'custom') {  
              awayTeamName = game.awayTeamName || 'Unknown Opponent';  
            } else {  
              awayTeamName = getTeamName(game.awayTeamId);  
            }  
  
            return (  
              <TableRow key={game.id}>    
                <TableCell>{game.date}</TableCell>    
                <TableCell>{homeTeamName}</TableCell>  
                <TableCell>{awayTeamName}</TableCell>  
                <TableCell>{game.location}</TableCell>    
                <TableCell>    
                  <div className={styles.actionButtons}>    
                    <Button onClick={() => handleEdit(game.id)}>Edit</Button>    
                    <Button    
                      onClick={() => handleDeleteClick(game)}    
                      appearance="secondary"    
                    >    
                      Delete    
                    </Button>    
                    <Button    
                      onClick={() => handleTrackGame(game.id)}    
                      appearance="primary"    
                    >    
                      Track Game    
                    </Button>    
                  </div>    
                </TableCell>    
              </TableRow>    
            );  
          })}    
        </TableBody>    
      </Table>    
  
      {/* Delete Confirmation Dialog */}    
      {deleteConfirmOpen && (    
        <Dialog    
          open={deleteConfirmOpen}    
          onOpenChange={() => setDeleteConfirmOpen(false)}    
        >    
          <DialogSurface>    
            <DialogTitle>Delete Game</DialogTitle>    
            <DialogContent>    
              Are you sure you want to delete the game between{' '}    
              {getTeamName(gameToDelete?.homeTeamId)} and{' '}  
              {gameToDelete?.awayTeamId === 'custom' ? gameToDelete?.awayTeamName : getTeamName(gameToDelete?.awayTeamId)}{' '}    
              on {gameToDelete?.date}?    
            </DialogContent>    
            <DialogActions>    
              <Button onClick={() => setDeleteConfirmOpen(false)}>    
                Cancel    
              </Button>    
              <Button    
                onClick={handleDeleteConfirm}    
                appearance="primary"    
                style={{ backgroundColor: tokens.colorPaletteRedBackground3 }}    
              >    
                Delete    
              </Button>    
            </DialogActions>    
          </DialogSurface>    
        </Dialog>    
      )}    
    </div>    
  );    
}    
  
export default ManageGamesPage;    
