// src/index.js  
/**  
 * File Name: index.js  
 * Location: src  
 * Description: Entry point of the React application.  
 */  
  
import './index.css'; 
import React from 'react';  
import ReactDOM from 'react-dom/client';  
import App from './App';  
  
const root = ReactDOM.createRoot(document.getElementById('root'));  
root.render(<App />);  
