// src/hooks/usePlayers.js  
  
import { useState, useEffect } from 'react';  
import PlayerController from '../controllers/PlayerController';  
  
export const usePlayers = () => {  
  const [players, setPlayers] = useState([]);  
  const [loading, setLoading] = useState(true); // Loading state  
  const [error, setError] = useState(null);     // Error state  
  
  useEffect(() => {  
    const fetchPlayers = async () => {  
      try {  
        const playersData = await PlayerController.getAllPlayers();  
        setPlayers(playersData);  
      } catch (error) {  
        console.error('Error fetching players:', error);  
        setError(error);  
      } finally {  
        setLoading(false);  
      }  
    };  
  
    fetchPlayers();  
  }, []);  
  
  return { players, loading, error };  
};  
