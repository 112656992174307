// frontend/src/components/ConnectionStatusBar.js  

import React, { useContext } from 'react';    
import { makeStyles, mergeClasses } from '@fluentui/react-components';    
import { AuthContext } from '../contexts/AuthContext';    

const useStyles = makeStyles({    
  statusBar: {    
    height: '4px',    
    width: '100%',    
    margin: 0,    
  },    
  authenticated: {    
    backgroundColor: '#00FF00', // Green    
  },    
  unauthenticated: {    
    backgroundColor: '#FF0000', // Red    
  },    
});    

function ConnectionStatusBar() {    
  const { isAuthenticated } = useContext(AuthContext);    
  const styles = useStyles();    

  const statusBarClass = mergeClasses(    
    styles.statusBar,    
    isAuthenticated ? styles.authenticated : styles.unauthenticated    
  );    

  return <div className={statusBarClass}></div>;    
}    

export default ConnectionStatusBar;  
