/**  
 * File Name: useGameClock.js  
 * Location: src/hooks  
 * Description: Custom hook to manage game clock functionality.  
 */  
  
import { useState, useEffect, useRef } from 'react';  
  
const useGameClock = (initialTime, onEndQuarter) => {  
  const [clockTime, setClockTime] = useState(initialTime);  
  const [isClockRunning, setIsClockRunning] = useState(false);  
  const clockIntervalRef = useRef(null);  
  
  useEffect(() => {  
    if (clockTime <= 0) {  
      pauseClock();  
      onEndQuarter();  
    }  
  }, [clockTime]);  
  
  const startClock = () => {  
    if (!isClockRunning) {  
      setIsClockRunning(true);  
      clockIntervalRef.current = setInterval(() => {  
        setClockTime((prevTime) => prevTime - 1);  
      }, 1000);  
    }  
  };  
  
  const pauseClock = () => {  
    if (isClockRunning) {  
      setIsClockRunning(false);  
      clearInterval(clockIntervalRef.current);  
    }  
  };  
  
  const resetClock = (newTime) => {  
    pauseClock();  
    setClockTime(newTime);  
  };  
  
  const addTime = (seconds) => {  
    setClockTime((prevTime) => Math.max(prevTime + seconds, 0));  
  };  
  
  return {  
    clockTime,  
    isClockRunning,  
    startClock,  
    pauseClock,  
    resetClock,  
    addTime,  
  };  
};  
  
export default useGameClock;  
