/**  
 * File Name: EventLogModal.js  
 * Location: src/components  
 * Description: Modal component to display the event log.  
 */  
  
import React from 'react';  
import {  
  Dialog,  
  DialogSurface,  
  DialogTitle,  
  DialogContent,  
  DialogActions,  
  Button,  
} from '@fluentui/react-components';  
import { Delete16Regular as DeleteIcon } from '@fluentui/react-icons';  
import { makeStyles } from '@fluentui/react-components';  
  
const useStyles = makeStyles({  
  eventLog: {  
    maxHeight: '300px',  
    overflowY: 'auto',  
    listStyleType: 'none',  
    padding: 0,  
  },  
});  
  
const EventLogModal = ({ isOpen, onDismiss, eventLog, onDeleteEvent }) => {  
  const styles = useStyles();  
  
  return (  
    <Dialog open={isOpen} onOpenChange={(_, data) => !data.open && onDismiss()}>  
      <DialogSurface>  
        <DialogTitle>Event Log</DialogTitle>  
        <DialogContent>  
          <ul className={styles.eventLog}>  
            {eventLog.map((entry, index) => (  
              <li key={index}>  
                {entry.description}  
                <Button  
                  icon={<DeleteIcon />}  
                  onClick={() => onDeleteEvent(entry)}  
                  appearance="subtle"  
                >  
                  Delete  
                </Button>  
              </li>  
            ))}  
          </ul>  
        </DialogContent>  
        <DialogActions>  
          <Button onClick={onDismiss}>Close</Button>  
        </DialogActions>  
      </DialogSurface>  
    </Dialog>  
  );  
};  
  
export default EventLogModal;  
